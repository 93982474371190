import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import MobileWarningBannerProps from './MobileWarningBannerProps'
import {
  IconButton,
  makeStyles
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: { borderColor: theme.palette.divider, textAlign: 'left' },
  icon: { color: `${theme.palette.grey[500]} !important` },
  action: { alignItems: 'flex-start', paddingTop: theme.spacing(0.5) }
}))

const MobileWarningBanner: React.FC<MobileWarningBannerProps> = (props) => {
  const { hidden, onClose } = props
  const classes = useStyles()
  return hidden ? null : (
    <Alert
      variant="outlined"
      severity="info"
      action={
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      }
      classes={{
        root: classes.root,
        icon: classes.icon,
        action: classes.action
      }}
    >
      <AlertTitle>Access all features on desktop</AlertTitle>
      Our mobile version has limited features.
      <br />
      For the best experience, switch to using your desktop.
    </Alert>
  )
}

export default MobileWarningBanner
