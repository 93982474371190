export interface PageStatus {
  totalItems: number
  currentPage: number
  pageSize: number
}

/**
 * This enum stores all common error types that are
 * shared between shopify and premium app.
 */
export enum ServerErrorType {
  outOfCredits = 'Out of credits',
  tooManyProductsSpecified = 'Too many products specified',
  noPermissionsDescribe = 'Describe Permission Error'
}

export enum DescribeErrorType {
  adTextFieldsError = 'Ad text fields error',
  blogFieldsError = 'Blog fields error',
  productFieldsError = 'Product fields error'
}

export interface DescribeError {
  product_id: string
  error: string
}

export enum JobStatus {
  loading = 'loading',
  success = 'success',
  new = 'new',
  failed = 'failed',
  notLoaded = 'not-loaded',
  error = 'error'
}

export interface Job {
  jobId: string
  totalCount: number
  currentCount: number
  status: JobStatus
  message?: string
  expiryStatus?: JobStatus
  expiryMessage?: string
  updatedAt: string
  createdAt: string
  metadata?: DescribeError[]
  jobName: string
  catalogueId?: string
}

export interface DashboardIncentiveOptions {
  /**
   * This interface contains the feature flags to show on the dashboard.
   */
  linkedin_post?: boolean
}

export interface FeatureFlags {
  // TODO: These probably don't need to be optional (they all have defaults on the backend)
  /**
   * Users with this flag set to True should see no Hypotenuse branding on the UI
   */
  white_label_ui: boolean
  multi_step_products: boolean
  add_import_export_products: boolean
  add_single_product: boolean
  minimize_product_card: boolean
  hide_navbar_logo: boolean
  show_import_template: boolean
  show_export_advanced_options: boolean
  enable_insights_page: boolean
  product_card_brand_text: string
  catalogue_tags_input_placeholder_text: string
  select_description_style_text?: string
  bubble_selected_descriptions_on_done_tab: boolean
  enable_seo_keywords_highlighting: boolean
  enable_catalogue_lvl_keywords_to_avoid: boolean
  enable_product_lvl_seo_keywords: boolean
  enable_product_lvl_keywords_to_avoid: boolean
  enable_image_import: boolean
  enable_review: boolean
  show_hershey_copy_type: boolean
  show_ubs_copy_type: boolean
  disable_add_product_if_no_products: boolean
  enable_image_generation: boolean
  enable_write_more: boolean
}

/**
 * This is an interface from globalappdata pulled from the backend misc table
 * that allows us to dynamically set global-level feature flags.
 * TODO: the feature flags in Constants.tsx should be moved here.
 */
export interface GlobalFeatureFlags {
  enable_image_superresolution?: boolean
  enable_linkedin_share?: boolean
  enable_bulk_workflows?: boolean
}

// region Charts & Chart Data
export interface Scalar {
  value: number | string
  label: string
}

export type LineDatum = string | number

export interface Point2D {
  x: LineDatum
  y: LineDatum
}

interface LineData {
  id: string
  data: Point2D[]
}

export interface LineChartData {
  lines: LineData[]
}

export interface PieSector {
  id: string
  label: string
  value: number
  color?: string
}

export interface PieChartData {
  sectors: PieSector[]
}

export enum TimePeriod {
  week = 'week',
  month = 'month',
  year = 'year'
}

export interface CreditsHistoryLineChartData {
  xLabel: string
  yLabel: string
  lines: LineData[]
}

export interface CreditsHistory {
  timePeriod: TimePeriod
  // TODO: Refactor LineChart on Account page to use the LineChartData interface
  lineChart: CreditsHistoryLineChartData
}
// endregion

// region Action Points
export enum ActionPointSeverity {
  info = 'Info',
  warning = 'Warning',
  severe = 'Severe'
}

/**
 * The entity that an action point refers to (e.g. product, store, etc.)
 */
export interface ActionPointSubject {
  /**
   * Unique identifier for the entity
   */
  id: string
  /**
   * Human-readable name for the entity
   */
  display_name?: string
}

export interface ActionPoint {
  /**
   * Short description of the action point
   */
  issue: string
  /**
   * The numeric score assigned to the action point
   */
  score: number
  severity: ActionPointSeverity
  /**
   * The entity that the action point refers to
   */
  subject: ActionPointSubject
  /**
   * URL for the action point to lead to
   */
  url?: string
  /**
   * Additional information about the action point
   */
  detail?: string
}
// endregion

export interface OrganizationUpdateForm {
  displayName: string
}

export enum PlanCreditProvisionType {
  add = 'add',
  reset = 'reset'
}

export enum PlatformType {
  shopify = 'shopify',
  premium = 'premium'
}

/**
 * Ad text Config Interface
 */
export interface AdTextConfigEntry {
  type: string
  product_id: string
}

export interface BlogGenHelperText {
  accordionSubtitleOneText?: string
  accordionSubtitleTwoText?: string
  accordionSubtitleThreeText?: string
  brandBackgroundHelperText?: string
  brandBackgroundPlaceholder?: string
  productDetailsHelperText?: string
  productDetailsPlaceholder?: string
  titleHelperText?: string
  titlePlaceholder?: string
  topicHelperText?: string
  topicPlaceholder?: string
  targetAudienceHelperText?: string
  targetAudiencePlaceholder?: string
  outlineHelperText?: string
  outlinePlaceholders?: string[]
  outlineDetailsPlaceholder?: string
  outlineHeadingPlaceholder?: string
  keywordsHelperText?: string
  keywordsPlaceholder?: string
}
export interface GlobalAppData {
  default_description_downvote_reasons: string[]
  blog_gen_helper_text: BlogGenHelperText
  blog_tone_options: string[]
  global_feature_flags: GlobalFeatureFlags
}

export interface LanguageDetail {
  code: string
  name: string
}

export interface TranslationLanguages {
  input: LanguageDetail[]
  output: LanguageDetail[]
}

export interface TranslationConfig {
  readonly show?: boolean
  language_from?: string
  language_to?: string
}

export interface TranslationConfigUpdateForm {
  language_from?: string
  language_to?: string
}

export interface PaginateableEntity<K> {
  last_key: K
}

/**
 * Social share
 */

export enum SocialShareMedia {
  facebook = 'Facebook',
  twitter = 'Twitter',
  linkedin = 'LinkedIn'
}

//============= Useful Abstract Types =============

/**
 * Extract a type containing un-assignable properties of T not assignable to U
 * e.g. Without<{a: number, b: string}, {a: number}> = {b: never}
 *
 * Source: https://stackoverflow.com/a/53229567
 */
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
/**
 * Extract a type containing either properties of T or U but not both and at least one of T or U
 *
 * e.g.
 *
 * const foo: XOR<{a: number}, {c: number}> = {a: 1} // OK
 *
 * const bar: XOR<{a: number}, {c: number}> = {c: 2} // OK
 *
 * const foobar: XOR<{a: number}, {c: number}> = {a: 1, c: 2} // Error
 *
 * const barfoo: XOR<{a: number}, {c: number}> = {} // Error
 *
 * Source: https://stackoverflow.com/a/53229567
 */
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

/**
 * Extract a type containing union of keys of T
 *
 * Source: https://tsplay.dev/wgLpBN
 */
export type UnionKeys<T> = T extends T ? keyof T : never

/**
 * Expand T to help intellisense.
 *
 * Source: https://tsplay.dev/wgLpBN
 */
export type Expand<T> = T extends T ? { [K in keyof T]: T[K] } : never

/**
 * Extract a type allowing exactly one type passed in the generic types array.
 *
 * e.g.
 *
 * const foo: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {a: 1} // OK
 *
 * const bar: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {b: '1'} // OK
 *
 * const foobar: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {a: 1, b: '1'} // Error
 *
 * const barfoo: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {} // Error
 */
export type OneOf<T extends {}[]> = {
  [K in keyof T]: Expand<
    T[K] & Partial<Record<Exclude<UnionKeys<T[number]>, keyof T[K]>, never>>
  >
}[number]
