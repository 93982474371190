import {
  ContentGenerationType,
  FeedbackContentGenerationType,
  GenerationStatusFilterKey
} from './ContentGeneration'
import { Description, ProductErrorType } from './Products'

/**
 * Enumeration for different ad text types used internally in generation.
 * Note: The order here is important and intentionally done.
 */
export enum AdTextType {
  rewriter = 'rewriter',
  summarise = 'summarise',
  instagram = 'instagram',
  google = 'google',
  facebook = 'facebook',
  linkedin = 'linkedin',
  headline = 'headline',
  landing_page = 'landing_page',
  meta_desc = 'meta_desc',
  category_page = 'category_page',
  marketing_angles = 'marketing_angles',
  eli5 = 'eli5',
  email = 'email',
  youtube_title = 'youtube_title',
  video_intro = 'video_intro',
  bio_writer = 'bio_writer',
  press_release = 'press_release'
}

/**
 * Enumeration of fields in the advertising text form.
 * As we scale the number of content types, we need to categorize fields to easily include/exclude them.
 * Initialized in order of top to bottom (keep it that way please?)
 */
export enum AdTextFormField {
  product = 'product',
  moreDescriptions = 'moreDescriptions',
  keywords = 'keywords',
  tone = 'tone',
  advancedOptions = 'advancedOptions',
  targetAudience = 'targetAudience',
  brand = 'brand'
}

export const adTextTabs = Object.entries(AdTextType).map(([value, key]) => {
  return {
    key: key,
    path: value
  }
})

export const generationTypeMap: {
  [key in AdTextType]: ContentGenerationType
} = {
  [AdTextType.instagram]: ContentGenerationType.advertising_text_instagram,
  [AdTextType.google]: ContentGenerationType.advertising_text_google,
  [AdTextType.facebook]: ContentGenerationType.advertising_text_facebook,
  [AdTextType.headline]: ContentGenerationType.advertising_text_headline,
  [AdTextType.linkedin]: ContentGenerationType.advertising_text_linkedin,
  [AdTextType.rewriter]: ContentGenerationType.advertising_text_rewriter,
  [AdTextType.category_page]:
    ContentGenerationType.advertising_text_category_page,
  [AdTextType.landing_page]:
    ContentGenerationType.advertising_text_landing_page,
  [AdTextType.eli5]: ContentGenerationType.advertising_text_eli5,
  [AdTextType.email]: ContentGenerationType.advertising_text_email,
  [AdTextType.video_intro]: ContentGenerationType.advertising_text_video_intro,
  [AdTextType.meta_desc]: ContentGenerationType.advertising_text_meta_desc,
  [AdTextType.marketing_angles]:
    ContentGenerationType.advertising_text_marketing_angles,
  [AdTextType.press_release]:
    ContentGenerationType.advertising_text_press_release,
  [AdTextType.summarise]: ContentGenerationType.advertising_text_summarise,
  [AdTextType.youtube_title]:
    ContentGenerationType.advertising_text_youtube_title,
  [AdTextType.bio_writer]: ContentGenerationType.advertising_text_bio_writer
}

export const feedbackContentTypeMap: {
  [key in AdTextType]: FeedbackContentGenerationType
} = {
  [AdTextType.instagram]:
    FeedbackContentGenerationType.advertising_text_instagram,
  [AdTextType.google]: FeedbackContentGenerationType.advertising_text_google,
  [AdTextType.facebook]:
    FeedbackContentGenerationType.advertising_text_facebook,
  [AdTextType.headline]:
    FeedbackContentGenerationType.advertising_text_headline,
  [AdTextType.linkedin]:
    FeedbackContentGenerationType.advertising_text_linkedin,
  [AdTextType.rewriter]:
    FeedbackContentGenerationType.advertising_text_rewriter,
  [AdTextType.category_page]:
    FeedbackContentGenerationType.advertising_text_category_page,
  [AdTextType.landing_page]:
    FeedbackContentGenerationType.advertising_text_landing_page,
  [AdTextType.eli5]: FeedbackContentGenerationType.advertising_text_eli5,
  [AdTextType.email]: FeedbackContentGenerationType.advertising_text_email,
  [AdTextType.video_intro]:
    FeedbackContentGenerationType.advertising_text_video_intro,
  [AdTextType.meta_desc]:
    FeedbackContentGenerationType.advertising_text_meta_desc,
  [AdTextType.marketing_angles]:
    FeedbackContentGenerationType.advertising_text_marketing_angles,
  [AdTextType.press_release]:
    FeedbackContentGenerationType.advertising_text_press_release,
  [AdTextType.summarise]:
    FeedbackContentGenerationType.advertising_text_summarise,
  [AdTextType.youtube_title]:
    FeedbackContentGenerationType.advertising_text_youtube_title,
  [AdTextType.bio_writer]:
    FeedbackContentGenerationType.advertising_text_bio_writer
}

export const AD_TEXT_TYPES_WITH_LONG_FORM_PRODUCT_TYPE_FIELD = new Set<AdTextType>(
  [
    AdTextType.rewriter,
    AdTextType.eli5,
    AdTextType.landing_page,
    AdTextType.summarise,
    AdTextType.email
  ]
)

export const AD_TEXT_TYPES_WITH_REQUIRED_PRODUCT_TYPE_FIELD = new Set<AdTextType>(
  [
    AdTextType.rewriter,
    AdTextType.eli5,
    AdTextType.landing_page,
    AdTextType.summarise,
    AdTextType.marketing_angles,
    AdTextType.email,
    AdTextType.youtube_title,
    AdTextType.video_intro,
    AdTextType.press_release,
    AdTextType.bio_writer
  ]
)

export const BULK_WORKFLOW_CONTENT_TYPES: readonly AdTextType[] = [
  AdTextType.instagram,
  AdTextType.rewriter,
  AdTextType.category_page,
  AdTextType.meta_desc
] as const

export const BULK_WORKFLOW_CONTENT_TYPES_SAMPLE_CSV = {
  [AdTextType.instagram]: '/instagram_sample.csv',
  [AdTextType.rewriter]: '/rewriter_sample.csv',
  [AdTextType.category_page]: '/category_page_sample.csv',
  [AdTextType.meta_desc]: '/meta_desc_sample.csv'
}

export type BulkWorkflowType = keyof typeof BULK_WORKFLOW_CONTENT_TYPES_SAMPLE_CSV
export const bulkWorkflowSampleCSV = (adTextType: AdTextType) =>
  adTextType in BULK_WORKFLOW_CONTENT_TYPES_SAMPLE_CSV
    ? BULK_WORKFLOW_CONTENT_TYPES_SAMPLE_CSV[adTextType as BulkWorkflowType]
    : undefined

/**
 * Field mappings to map errors to the right field.
 * TODO: Use them
 */
export enum GenerateAdTextErrorType {
  empty_fields = 'empty_fields',
  target_audience_too_long = 'target_audience_too_long',
  brand_too_long = 'brand_too_long',
  keywords_too_long = 'keywords_too_long',
  product_type_too_long = 'product_type_too_long'
}

export const errorToFieldMap: {
  [key in GenerateAdTextErrorType]: AdTextFormField | null
} = {
  [GenerateAdTextErrorType.empty_fields]: null, // Empty Fields is specially mapped to null because this could be any field
  [GenerateAdTextErrorType.target_audience_too_long]:
    AdTextFormField.targetAudience,
  [GenerateAdTextErrorType.brand_too_long]: AdTextFormField.brand,
  [GenerateAdTextErrorType.keywords_too_long]: AdTextFormField.keywords,
  [GenerateAdTextErrorType.product_type_too_long]: AdTextFormField.product
}

export enum AdvertisementTextActions {
  copy = 'copy',
  edit = 'edit',
  delete = 'delete'
}

export type AdCardKey = AdTextType | 'suggestion'
export interface AdCardDetails {
  key: AdCardKey
  title: string
  subtitle: string
  onClick: () => void
  logoSrc?: string
}

export interface AdText {
  username: string
  organizationName: string
  adTextId: string
  productId: string
  created_at: string
  ad_text_type: AdTextType
  loading: boolean
  descriptions: Description[] // TODO: Make readonly
  loadingAt?: Date
  expiredAt?: Date
  productErrorType?: ProductErrorType
  target_audience?: string
  brand?: string
  tone?: string
  product_type?: string
  more_description?: string
  keywords?: string[]
  isSaved?: boolean
}

export interface AdTextUpdateForm {
  adTextId: string
  target_audience?: string
  tone?: string
  more_description?: string
  brand?: string
  product_type?: string
  keywords?: string[]
  descriptions?: Description[]
  productErrorType?: ProductErrorType
  isSaved?: boolean
}

export interface AdTextFolder {
  id: string
  username: string
  organizationName: string
  ad_text_type: AdTextType
  created_at: string
  updated_at: string
  folder_name: string
  item_count: number
  tone?: string
}

export interface AdTextFolderUpdateForm {
  folder_name: string
  tone: string
}

export interface AdTextFoldersCount {
  total_count: number
  ad_text_counts: { [key in AdTextType]: number }
}

export enum BeaconArticleIdSlots {
  googleKeywords = 'googleKeywords',
  googleTargetAudience = 'googleTargetAudience',
  facebookKeywords = 'facebookKeywords',
  facebookTargetAudience = 'facebookTargetAudience',
  adsGeneration = 'adsGeneration'
}

/**
 * Each ad text type is mapped to a set of fields that do NOT require this field.
 * Used to know which field(s) to not render.
 */

export const formExceptionMap = {
  [AdTextType.google]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.instagram]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.facebook]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.linkedin]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.headline]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.category_page]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.video_intro]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.meta_desc]: new Set<AdTextFormField>([
    AdTextFormField.tone,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.marketing_angles]: new Set<AdTextFormField>([
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience
  ]),
  [AdTextType.press_release]: new Set<AdTextFormField>([
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.targetAudience,
    AdTextFormField.tone
  ]),
  [AdTextType.summarise]: new Set<AdTextFormField>([
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.rewriter]: new Set<AdTextFormField>([
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.eli5]: new Set<AdTextFormField>([
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.tone,
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.email]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.youtube_title]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions,
    AdTextFormField.brand
  ])
}

/**
 * Each ad text type is mapped to a set of fields that are required.
 * Used to decide whether to disable the generate button or not.
 * Note that a field may not be required but not excepted either.
 */
export const requiredFieldMap = {
  [AdTextType.google]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.instagram]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.facebook]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.linkedin]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.landing_page]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.headline]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.category_page]: new Set<AdTextFormField>([
    AdTextFormField.keywords
  ]),
  [AdTextType.video_intro]: new Set<AdTextFormField>([
    AdTextFormField.keywords
  ]),
  [AdTextType.meta_desc]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.marketing_angles]: new Set<AdTextFormField>([
    AdTextFormField.product
  ]),
  [AdTextType.press_release]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.moreDescriptions,
    AdTextFormField.brand
  ]),
  [AdTextType.summarise]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.rewriter]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.eli5]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.email]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.youtube_title]: new Set<AdTextFormField>([
    AdTextFormField.keywords
  ])
}

export type AdTextExceptionFieldTypes = keyof typeof formExceptionMap
export type AdTextRequiredFieldTypes = keyof typeof requiredFieldMap
export const formExceptionSet = (adTextType: AdTextType) =>
  adTextType in formExceptionMap
    ? formExceptionMap[adTextType as AdTextExceptionFieldTypes]
    : undefined

export const requiredFieldSet = (adTextType: AdTextType) =>
  adTextType in requiredFieldMap
    ? requiredFieldMap[adTextType as AdTextRequiredFieldTypes]
    : undefined

export const adHeaders = {
  [AdTextType.google]: 'Google ads',
  [AdTextType.instagram]: 'Instagram captions',
  [AdTextType.facebook]: 'Facebook ads',
  [AdTextType.headline]: 'Website headlines and taglines',
  [AdTextType.linkedin]: 'Linkedin posts',
  [AdTextType.rewriter]: 'Rewrite content',
  [AdTextType.category_page]: 'Category page descriptions',
  [AdTextType.landing_page]: 'Landing page content',
  [AdTextType.eli5]: 'Simplify language',
  [AdTextType.email]: 'Email copy',
  [AdTextType.video_intro]: 'Video hook and introduction',
  [AdTextType.meta_desc]: 'Meta titles and descriptions',
  [AdTextType.marketing_angles]: 'Marketing angles',
  [AdTextType.press_release]: 'Press releases',
  [AdTextType.summarise]: 'Summarize content', // Note the change in spelling (UK to US)
  [AdTextType.youtube_title]: 'Youtube titles and descriptions',
  [AdTextType.bio_writer]: 'Bio Writer'
}

export const adSubHeaders = {
  [AdTextType.google]:
    'Create effective and persuasive ads that generate leads and sales.',
  [AdTextType.instagram]:
    'Write scroll-stopping captions that encourages people to stop, look, and like.',
  [AdTextType.facebook]:
    'Craft powerful and compelling ads that speak to your target market.',
  [AdTextType.headline]:
    'Develop headlines and taglines that pack a punch and convert visitors into customers.',
  [AdTextType.linkedin]:
    'Engage your target audience with thought-provoking and persuasive content.',
  [AdTextType.rewriter]:
    'Refresh and repurpose content while making it more engaging and effective.',
  [AdTextType.category_page]:
    'Optimize your category pages with descriptions that increase organic traffic.',
  [AdTextType.landing_page]:
    'Create benefit-focused landing pages that entice visitors to take the next step.',
  [AdTextType.eli5]:
    'Make complex concepts easy to understand and jargon-free.',
  [AdTextType.email]:
    'Write copy to get your message across, no matter how crowded the inbox.',
  [AdTextType.video_intro]:
    'Write a catchy and intriguing hook to reel viewers in and keep them watching.',
  [AdTextType.meta_desc]:
    'Write meta titles and descriptions that are optimized for search engines.',
  [AdTextType.marketing_angles]:
    'Generate marketing angles that are persuasive and compelling.',
  [AdTextType.press_release]:
    'Write press releases that are newsworthy and engaging.',
  [AdTextType.summarise]:
    'Summarize content to make it more concise and say more with less.', // Note the change in spelling (UK to US)
  [AdTextType.youtube_title]:
    'Optimize your videos for greater visibility and higher engagement.',
  [AdTextType.bio_writer]:
    'Elevate any page with a well-written bio that will make people want to know more'
}

export interface AdTextFolderSearchQuery {
  folderId?: string | null
  search?: string
  generationStatus?: GenerationStatusFilterKey
  keywords?: string[]
  scan?: boolean
  start?: number
  end?: number
}

export interface AdTextSearchResultInResponse {
  adTexts: AdText[]
  totalAdTexts: number
}

export interface AdTextFolderOverviewSearchQuery {
  ad_text_type: AdTextType
  search?: string
  scan?: boolean
  sort_by?: string[]
  start?: number
  end?: number
}

export interface AdTextFolderOverviewInResponse {
  adTextFolders: AdTextFolder[]
  totalFolders: number
}
