import { Message } from '@papercups-io/chat-widget/dist/api'
import { BlogWriteMoreAction } from '../interfaces/Blog'
import { Description } from '../interfaces/Products'
import { apiClient } from '../utils/ApiClient'
import { ActionPoint } from '../utils/Interfaces'

export const handleTrackAdvertisingTextAdvancedOptionsClick = (
  isOpen: boolean
) => {
  return apiClient.post<void>('/analytics/advertising/advanced_options', null, {
    params: {
      is_open: isOpen
    }
  })
}

export const trackCustomerSupportMessageReceived = (message: Message) => {
  const messageReceived = message.body
  return apiClient.post<void>(
    '/analytics/customer_support_message',
    JSON.stringify(messageReceived)
  )
}

export const handleTrackDescriptionSelect = (
  description: Description,
  product_id: string
) => {
  const data = {
    description: description,
    product_id: product_id
  }
  return apiClient.post<void>('/analytics/product/description_select', data)
}

export const handleTrackDescriptionCopy = (
  description: Description,
  productId: string
) => {
  return apiClient.post<void>('/analytics/product/description_copy', {
    description: description,
    product_id: productId
  })
}

export const handleTrackDescriptionDelete = (
  description: Description,
  productId: string
) => {
  return apiClient.post<void>('/analytics/product/description_delete', {
    description: description,
    product_id: productId
  })
}

export const handleTrackCustomerContactUs = () => {
  return apiClient.post<void>('/analytics/customer_contact_us')
}

/**
 * Log to analytics that a user clicked 'learn more' on a feature.
 * @param {string} feature The feature the user clicked 'learn more' on
 */
export const handleTrackCustomerLearnMore = (feature?: string) => {
  return apiClient.post<void>('/analytics/customer_learn_more', null, {
    params: { feature }
  })
}

export const handleTrackCustomerWantsCustomImportFile = () => {
  return apiClient.post<void>('/analytics/customer_custom_file_import')
}

export const handleTrackCustomerViewApiDocs = () => {
  return apiClient.post<void>('/analytics/customer_view_api_docs')
}

export const handleTrackEnterprisePlanContactUs = () => {
  return apiClient.post<void>('/analytics/customer_enterprise_plan_contact_us')
}

// ===== Insights dashboard analytics =====
/**
 * To be invoked when the action button on an action point is clicked
 * @param {ActionPoint} action_point The action point that was clicked
 */
export const handleTrackInsightsActionPointClick = async (
  action_point: ActionPoint
) => {
  return apiClient.post<void>(
    '/analytics/insights_action_point_click',
    action_point
  )
}

// ===== General Analytics =====
export const handleTrackPageVisit = async (pathname: string) => {
  return apiClient
    .post('/analytics/page_visit', null, {
      params: {
        pathname: pathname
      }
    })
    .then((_resp) => {})
    .catch((error) => {
      console.log(error)
    })
}

// ===== Blog Analytics =====
export const handleTrackExportBlogPosts = async (
  blogId: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/export_posts',
    {},
    {
      params: { blog_id: blogId, export_type: 'docx' }
    }
  )
}

export const handleTrackWriteMoreAction = async (
  blogId: string,
  blogWriteMoreAction: BlogWriteMoreAction
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/blog_write_more_action',
    {},
    {
      params: { blog_id: blogId, blog_write_more_action: blogWriteMoreAction }
    }
  )
}

// ===== Text to Image Generation Analytics =====
export const handleTrackTextToImageDownload = async (
  text_prompt: string,
  imageId: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/text_to_image/text_to_image_download',
    {},
    {
      params: { text_prompt, image_id: imageId }
    }
  )
}

// ===== Referral Analytics =====
export const handleTrackSocialPostLinkSubmitted = async (
  shareUrl: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/blog_social_post_link_submitted',
    {},
    {
      params: { share_url: shareUrl }
    }
  )
}
