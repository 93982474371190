/**
 * This module provides Help Scout's Beacon script via a hook.
 */
import React, { useEffect, useState } from 'react'
import { LiveChatLoaderProvider, useChat } from 'react-live-chat-loader'
import { Beacon } from './types'

export interface BeaconProps {
  /**
   * The window.Beacon object - this should be undefined until the script is fully loaded.
   */
  beacon?: Beacon
  isBeaconLoaded: boolean
  disableBeaconLinks?: boolean
}

/**
 * Default value in the context is undefined.
 * Once the global Beacon script in the <script> tag executes,
 * @type {React.Context<Beacon | undefined>}
 */
export const BeaconContext = React.createContext<BeaconProps | undefined>(
  undefined
)

export interface InnerBeaconProviderProps {
  disableBeaconLinks?: boolean
}

export interface BeaconProviderProps extends InnerBeaconProviderProps {
  beaconId: string
}

/**
 * The 'inner' provider component that immediately initializes Beacon on mount.
 */
const InnerBeaconProvider: React.FC<InnerBeaconProviderProps> = ({
  children,
  disableBeaconLinks
}) => {
  // Initialize Beacon on mount
  const [state, loadChat] = useChat()
  if (state === undefined) {
    throw new Error(
      "LiveChatLoaderProvider's `providerKey` not set. Did you forget to add <LiveChatLoaderProvider>?"
    )
  }
  useEffect(() => loadChat({ open: false }), [])

  // Ensure that the `beacon` returned by the hook is undefined until fully loaded.
  const [isBeaconLoaded, setIsBeaconLoaded] = useState<boolean>(false)
  useEffect(() => {
    if (state === 'complete') setIsBeaconLoaded(true)
  }, [state])
  const beacon = isBeaconLoaded ? window.Beacon : undefined

  return (
    <BeaconContext.Provider
      value={{ beacon, isBeaconLoaded, disableBeaconLinks }}
    >
      {children}
    </BeaconContext.Provider>
  )
}

/**
 * The main BeaconProvider component that's meant to directly wrap the app component
 */
export const BeaconProvider: React.FC<BeaconProviderProps> = ({
  beaconId,
  ...restProps
}) => {
  return (
    /**
     * LiveChatLoaderProvider must wrap the inner provider because
     * the inner provider calls `useChat` to immediately initialize Beacon.
     */
    <LiveChatLoaderProvider provider="helpScout" providerKey={beaconId}>
      <InnerBeaconProvider {...restProps} />
    </LiveChatLoaderProvider>
  )
}
