import React, { useMemo, useState } from 'react'
import { SearchStatus } from '../Interfaces'
import { makePropInjectorHOCFromHook } from '@hypotenuse/common/src/hooks/hocify'

export interface ProductStateProps {
  searchStatus: SearchStatus
  setSearchStatus: React.Dispatch<React.SetStateAction<SearchStatus>>
}

const ProductStateContext = React.createContext<ProductStateProps | undefined>(
  undefined
)

export const ProductStateProvider: React.FC = (props) => {
  const [_searchStatus, setSearchStatus] = useState<SearchStatus>({
    text: '',
    isSearching: false,
    hasError: false,
    resultText: ''
  })

  // Memoize to avoid unnecessary rerenders later
  const { text, isSearching, hasError, resultText } = _searchStatus
  const searchStatus = useMemo(
    () => ({
      text,
      isSearching,
      hasError,
      resultText
    }),
    [text, isSearching, hasError, resultText]
  )

  return (
    <ProductStateContext.Provider value={{ searchStatus, setSearchStatus }}>
      {props.children}
    </ProductStateContext.Provider>
  )
}

export const useProductState = () => {
  const context = React.useContext(ProductStateContext)
  if (context === undefined) {
    throw new Error(
      'useProductState must be used within a ProductStateProvider'
    )
  }
  return context
}

/**
 * HOC wrapper for the context hook.
 */
export const withProductState = makePropInjectorHOCFromHook(useProductState)
