import {
  AdTextConfigEntry,
  DashboardIncentiveOptions,
  FeatureFlags,
  PlanCreditProvisionType
} from '@hypotenuse/common/src/utils/Interfaces'

export interface Credits {
  left: number
  total: number
}

export interface CatalogueIdName {
  catalogueId: string
  catalogueName: string
}

export interface InterviewConfig {
  show: boolean
  credits?: number
  voucherDisplay?: string
}

export interface InterviewConfigUpdateRequest {
  show?: boolean
  credits?: number
  voucherDisplay?: string
}

export interface BulkProductDescriptionsConfig {
  show: boolean
}

export interface CouponConfig {
  show: boolean
}

export interface DetectedProductLanguageConfig {
  was_detected: boolean
  was_shown: boolean
  language?: string
}

export interface BlogGenConfig {
  show: boolean
}

export interface ServiceConfigs {
  coupons: CouponConfig
  review_popup: ReviewPopupConfig
  interview: InterviewConfig
  bulk_product_descriptions: BulkProductDescriptionsConfig
  detected_language: DetectedProductLanguageConfig
  feature_flags?: FeatureFlags
  blog_gen_config: BlogGenConfig
  dashboard_incentive_options?: DashboardIncentiveOptions
}

export enum PlanTierLogicForBulkPurchaseDuringTrialMode {
  cheapest = 'cheapest'
}

export interface User {
  username: string
  displayName?: string
  admin: boolean
  creditsLeft: number
  creditsTotal: number
  monthlyCreditAmount: number
  creditProvisionType: PlanCreditProvisionType
  hasAccess: boolean
  inTrial: boolean
  startTrialDate: string
  completedToursIds: string[]
  dialogs: Dialog[]
  ad_text: AdTextConfigEntry[]
  service_configs: ServiceConfigs
}

export interface Dialog {
  id: string
  show: boolean
}

export interface ShopStaff {
  last_name: string
  first_name: string
  email: string
}

export interface ShopDisplay {
  name: string
  email: string
  domain: string
  currency: string
  country_name: string
  plan_name: string
}

export interface ShopDisplayDataAndStaffResponse extends ShopDisplay {
  current_staff?: ShopStaff
}

export enum PlanType {
  free = 'free',
  starter = 'starter',
  member = 'member',
  enterprise = 'enterprise'
}

export enum Currency {
  usd = 'usd'
}

export interface PriceInfo {
  price: number
  credit_amount: number
}

export interface PlanTier {
  plan_id: string
  plan_type: PlanType
  plan_display_name: string
  plan_description: string
  plan_price: number
  plan_monthly_credit_amount: number
  plan_incentives: string[]
  pay_as_you_go_prices: PriceInfo[]
  currency: Currency
  credit_provision_type: PlanCreditProvisionType
  discounted_plan_price?: number
  discounted_pay_as_you_go_prices?: PriceInfo[]
  boosted_plan_monthly_credit_amount?: number
}

export interface Coupon {
  coupon_id: string
  redemption_expiry_date: string
  coupon_total: number
  coupon_left: number
  maximum_redemption_per_user: number
  applicable_plan_types: PlanType[]
  coupon_benefits: CouponBenefits
}

export interface CouponBenefits {
  plan_price_discount_percentage: number
  plan_monthly_credits_boost_percentage: number
  credits_bundle_price_discount_percentage: number
  credits_bundle_amount_boost_percentage: number
}

export enum PlanTierSegment {
  top,
  bottom,
  both,
  legacy
}

export enum PaymentHistoryStatus {
  active = 'active',
  cancelled = 'cancelled' //This status is only applicable for recurring application charge when user cancel subscription bar
}

export interface PaymentHistory {
  id: string
  user: string
  description: string
  currency: Currency
  price: number
  created_at: Date
  status: PaymentHistoryStatus
}

export enum TransactionReason {
  charge_product_tagging = 'charge_product_tagging',
  charge_product_descriptions = 'charge_product_descriptions',
  refund_product_tagging = 'refund_product_tagging',
  refund_product_descriptions = 'refund_product_descriptions',
  refund_blog_descriptions = 'refund_blog_descriptions',
  purchase_credits_bundle = 'purchase_credits_bundle',
  purchase_subscription = 'purchase_subscription',
  free_first_time_user = 'free_first_time_user',
  admin_add_credit = 'admin_add_credit',
  admin_deduct_credit = 'admin_deduct_credit'
}

export interface CreditTransaction {
  username: string
  createdAt: string
  transactionId: string
  creditsChange: number
  creditsLeftBefore: number
  creditsTotalBefore: number
  reason: TransactionReason
}

export interface FilterStatus {
  possibleFilters: FilterTermsGroup[]
  selectedFilters: FilterTermsGroup[]
}

export interface FilterTermsGroup {
  text: string
  key: string
  values: string[]
}

export interface SearchStatus {
  text: string
  isSearching: boolean
  hasError: boolean
  resultText: string
}

export interface SortStatus {
  key: string
  reverse: boolean
}

export enum JobStatus {
  loading = 'loading',
  success = 'success',
  new = 'new',
  failed = 'failed',
  notLoaded = 'not-loaded',
  error = 'error'
}

export interface ProductSyncStatus {
  currentCount?: number
  totalCount?: number
  status: 'loading' | 'success' | 'new' | 'failed' | 'not-loaded' | 'error'
  message: string
  hasShopifyProducts?: boolean
  hasInternalProducts?: boolean
}

export interface DescribeError {
  product_id: string
  error: string
}

export interface Job {
  jobId: number
  totalCount?: number
  currentCount: number
  status: JobStatus
  message?: string
  expiryStatus?: JobStatus
  expiryMessage?: string
  updatedAt: string
  metadata?: DescribeError[]
}

export enum ProductTabType {
  all = 'all',
  generated = 'generated',
  new = 'new',
  published = 'published',
  archived = 'archived'
}
export interface TabStatus {
  deleteProductOnExport: boolean
  key: ProductTabType
}

export interface Tab {
  label: string
  deleteProductOnExport: boolean
  key: ProductTabType
  count?: number
}

export interface AppNotification {
  color:
    | 'green'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'olive'
    | 'teal'
    | 'blue'
    | 'violet'
    | 'purple'
    | 'pink'
    | 'brown'
    | 'grey'
    | 'black'
    | undefined
  size:
    | 'large'
    | 'big'
    | 'small'
    | 'mini'
    | 'tiny'
    | 'huge'
    | 'massive'
    | undefined
  header: string
  content: string
}

export type NotificationSeverity = 'success' | 'info' | 'warning' | 'error'

export interface Notification {
  severity?: NotificationSeverity
  content: string
  dateKey: Date // Since Material UI Toast only allow 1 toastbar per component, we need to set a unique key to have multiple toastBar
  duration: number
  renderAction?: Function // For toast bar with action(e.g. undo when clicked)
}

export enum WriterLength {
  'regular',
  'extended'
}

export interface WriterChoice {
  writer: string
  number_selected: number
}

export interface DescriptionStyle {
  writer: string
  length: WriterLength
  category: string
  display: string
  disabled: boolean
  count: number
}

export interface TranslationConfig {
  language_from: string
  language_to: string
}

export enum ApiError {
  unauthorized
}

export enum FeedbackType {
  shopifyFeatureRequest = 'shopify_feature_request'
}

export interface RedeemedReferral {
  referral_code: string
  redeemed_username: string
  datetime_redeemed: Date
}

export interface ReviewTemplateInRequest {
  template_text: string
  selected: boolean
}

export interface ReviewTemplate {
  template_id: string
  template_text: string
  selected: boolean
  num_generated?: number
  created_at?: string
  edited?: boolean
}

export interface ReviewPopupConfig {
  show: boolean
  total_leave_review_button_clicked: number
  total_review_popup_closed: number
  total_generate_review_template: number
  generated_review_template: string
}

export enum ReportType {
  generate = 'generate',
  payment = 'payment'
}

export interface ShopifyFAQ {
  question: string
  answer: string
}

export interface ShopifyPricingCopy {
  howCreditsWork: string
  subscription: string
  additionalCredits: string
}

export interface ShopifyUserPricingDetailsInResponse {
  pricing_copy: ShopifyPricingCopy
  plan_tier_list: PlanTier[]
  active_plan_tier: PlanTier
  subscribed_plan_tier: PlanTier
  trial_days: number
  plan_tier_logic_for_bulk_purchase_during_trial_mode?: PlanTierLogicForBulkPurchaseDuringTrialMode
  credit_provision_type: PlanCreditProvisionType
  next_credit_provision_date: Date
  override_expiry_date?: Date
}

export interface ServerError {
  error: string
  error_message: string
}

export interface UserPlanTierDetailsUpdateForm {
  key_attr_name: string
  user: string
  override_plan_id?: string
  override_should_give_monthly_credits?: boolean
  override_expiry_date?: Date
}

export interface UserPlanTierDetails {
  user: string
  plan_id: string
  plan_list_id: string
  active_subscription_id: string
  last_subscription_credit_transaction_id: string
  subscription_coupon_id?: string
  override_plan_id?: string
  override_should_give_monthly_credits?: boolean
  override_expiry_date?: Date
}

/* Strength bar component interfaces */

// decalre strength levels
export enum KeywordStrength {
  weak = 2,
  average = 4,
  strong = 8
}

// map strength to color
export interface KeywordBarStyle {
  [KeywordStrength.weak]: string
  [KeywordStrength.average]: string
  [KeywordStrength.strong]: string
}

// props to be passed to strength bar component
export interface StrengthBarProps {
  numBars: number
  numColored: number
  color: string
  message: string
}

export interface PlanCardProps {
  planTierList?: PlanTier[]
  activePlanTier?: PlanTier
  subscribedPlanTier?: PlanTier
  user?: User
  appliedCouponCode: string
  setNotification: Function
  setLoading: Function
  resetAppliedCouponAndFetchPlanList: Function
  fetchUser: Function
}

export interface ShopifyTrialPlanDetailsResponse {
  plan_display_name: string
  plan_description: string
  plan_incentives: string[]
  plan_free_credits: number
}
