import snackbar from './Snackbar'
import { OptionsObject, SnackbarMessage } from 'notistack'
import React, { DependencyList, EffectCallback, useEffect, useRef } from 'react'
import { Button } from '@material-ui/core'
import { Description } from '../interfaces/Products'
import { MIN_EXPECTED_OUTLINES, HAS_SEEN_PAPERCUPS, months } from './Constants'
import { format, parseISO } from 'date-fns'
import moment from 'moment'

// ==== Ad Descriptions ====
export const constructAdDescription = (
  title: string,
  description: string
): string => {
  return `${title}\nDescription:${description}`
}

interface parsedDescription {
  title: string
  description: string
}

export const parseAdDescription = (
  description: Description
): parsedDescription => {
  const [title, desc] = description.content.split('\nDescription:', 2)
  // Do not trim white space at the end of description. This will cause a bug where the user cannot type any spaces
  // when editing their descriptions at the end.
  return {
    title: title ?? '',
    description: desc ? desc.trimStart() : ''
  }
}

// ==== Snackbar ====
interface ShowWithSnackbarOptions<
  ClickType = any,
  EnterType = any,
  ExitType = any
> {
  onClick?: () => ClickType
  onEnter?: () => EnterType
  onExit?: () => ExitType
  // TODO: Confirm button?
  buttonText?: string
  snackbarOptions?: Exclude<OptionsObject, 'onEnter' | 'onClose' | 'action'>
}

/**
 * Display a snackbar with:
 * - an 'enter' callback when the snackbar is first displayed
 * - an 'exit' callback when it closes 'naturally'
 * - a 'click' callback when it's closed programmatically (i.e. with a button click)
 * @param {SnackbarMessage} message
 * @param {ShowWithSnackbarOptions} options
 */
export const showWithSnackbar = function <
  ClickType = any,
  EnterType = any,
  ExitType = any
>(
  message: SnackbarMessage,
  options: ShowWithSnackbarOptions<ClickType, EnterType, ExitType>
): ClickType | EnterType | ExitType | undefined {
  let result: ClickType | EnterType | ExitType | undefined

  snackbar.show(message, {
    ...options.snackbarOptions,
    onEnter: (_node, _key) => {
      result = options.onEnter?.()
    },
    onClose: (_event, reason) => {
      // When close is not triggered programmatically (i.e. user clicked cancel), trigger exit
      if (reason !== 'instructed' && reason !== 'clickaway') {
        result = options.onExit?.()
      }
    },
    action: options.onClick
      ? (snackbarKey) => (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              result = options.onClick?.()
              snackbar.close(snackbarKey)
            }}
            style={{ fontWeight: 'bold' }}
          >
            {options.buttonText ?? 'Cancel'}
          </Button>
        )
      : undefined
  })

  return result
}

// ==== Papercups ====
export const setHasSeenPapercups = (hasSeen: boolean): void => {
  localStorage.setItem(HAS_SEEN_PAPERCUPS, hasSeen.toString())
}

export const getHasSeenPapercups = (): boolean => {
  return localStorage.getItem(HAS_SEEN_PAPERCUPS) === 'true'
}

// ==== Misc ====
export const argSort = <T extends any = any>(
  unsortedArr: T[],
  compareFn: (a: T, b: T) => number
): number[] =>
  unsortedArr
    // Convert to an object containing the original value and its index
    .map((item, index) => ({ item, index }))
    // Sort the array of objects by the original value
    .sort((a, b) => compareFn(a.item, b.item))
    // Map to sorted indices
    .map(({ index }) => index)

export const isStringUrl = (possible_url: string) => {
  // Basic check to see if string is URL. Can be more robust, but for now just need a basic one
  const url_prefixes = ['www.', 'https:', 'http:']
  return url_prefixes.some((prefix) => possible_url.includes(prefix))
}

// Functions to count characters
const stripHtml = (s: string) => {
  s = s.replace(/<[^>]*>?/gm, '')
  return s
}
export const countWords = (s: string) => {
  /**
   * Counts the number of words within a string.
   *
   * Reference: https://stackoverflow.com/questions/32311081/check-for-special-characters-in-string
   */
  const format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/

  s = stripHtml(s)
  const s_split = s
    .replace(/&nbsp;/g, ' ')
    .trim()
    .split(/[\s\r\n]+/)
  const s_split_remove_empty = s_split
    .map((s) => s.trim())
    .filter((s) => s !== '' && !s.match(format))
  return s_split_remove_empty.length
}

export const countCharacters = (s: string) => {
  s = stripHtml(s)
  s = s.replace(/&nbsp;/g, '') // Newline will be represented as &nbsp leading to wrong number of chars so we need to strip it
  s = s.trimEnd() // Trim off any whitespace at the end
  return s.length
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// ==== Dates ====
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    // Note: The Z at the back of parseISO is necessary because we do UTC now.
    // We can remove the Z if we migrate our python datetime to use the ISO8601 spec.
    // See more here: https://stackoverflow.com/questions/19654578/python-utc-datetime-objects-iso-format-doesnt-include-z-zulu-or-zero-offset
    if (isIsoDateString(value)) body[key] = parseISO(`${value}Z`)
    else if (typeof value === 'object') handleDates(value)
  }
}

export function getDatetimeSince(date: Date): string {
  const currDate = new Date()
  let diffDays = currDate.getDate() - date.getDate()
  let diffMonths = currDate.getMonth() - date.getMonth()
  let diffYears = currDate.getFullYear() - date.getFullYear()

  // If date is today or yesterday, display time since (eg 4 hours ago, 2 minutes ago)
  if (diffYears === 0 && diffDays < 2 && diffMonths === 0) {
    return moment(date).fromNow()
  } else {
    return formatDateString(date)
  }
}

export function formatDateString(date: Date) {
  return format(date, 'dd MMM yyyy')
}

export function getISOStringWithoutZ(date: Date) {
  return date.toISOString().replace('Z', '')
}

export const formatDate = (date: Date): string => {
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export const getMonthName = (date: Date): string => {
  return months[date.getMonth()]
}

// An custom React Hook to run callback functions after delay using setInterval
// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) {
      return
    }
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

// ==== Scroll ====
export const scrollToElementById = (elementId: string) => {
  const element = document.getElementById(elementId)
  element?.scrollIntoView({ behavior: 'smooth' })
}

// A useEffect that runs only during the second call.
// This is especially useful when you dont want useEffect to be called during the initial render of the component.
export const useNonInitialEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      effect()
    }
  }, deps)
}

export const padArray = <T,>(array: T[], minLen: number, fillValue: T) => {
  return minLen > array.length
    ? array.concat(Array(minLen - array.length).fill(fillValue))
    : array
}

// ==== Blog ====
// Pads the outline array with empty strings to reach the minimum length
export const padOutlinesArray = (arr: string[]) => {
  return padArray(arr, MIN_EXPECTED_OUTLINES, '')
}

/**
 * Trim whitespace from the beginning and end of a string.
 */
export const trimNewlineAndWhitespace = (s: string) => {
  return s.replace(/^\s+|\s+$/g, '')
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  document.body.removeChild(link)
}
