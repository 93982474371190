import React, { Suspense } from 'react'
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom'
import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import * as Sentry from '@sentry/react'
import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider } from '@material-ui/core/styles'
import initHelpHero, { HelpHero } from 'helphero'
import { SnackbarProvider } from 'notistack'

import SlimDrawer from './components/SlimDrawer'
import {
  DetectedProductLanguageConfig,
  ProductSyncStatus,
  ShopDisplayDataAndStaffResponse,
  User
} from './utils/Interfaces'
import ChatSupport from './components/ChatSupport'
import './App.css'
import Analytics from './analytics/Analytics'
import UnexpectedError from './components/UnexpectedError'
import { ApiErrorCatcher } from './components/ApiErrorHandler'
import { handleTrackUserSession } from './api/Analytics'
import { theme } from './utils/Theme'
import {
  adTextTabs,
  AdTextType
} from '@hypotenuse/common/src/interfaces/AdvertisingText'
import {
  DISABLE_BEACON_LINKS,
  // GTM_CONTAINER_ID,
  HELPSCOUT_BEACON_ID,
  SHOPIFY_HELPHERO_TUTORIAL_TOUR,
  TAKO_ENABLE_ARTICLE_PUBLIC_SHARING,
  TAKO_ENABLE_NEW_REFERRALS
} from './utils/Constants'
import { getIsTutorialComplete } from './utils/Functions'
import { getHasSeenPapercups } from '@hypotenuse/common/src/utils/Functions'
import snackbar, {
  SnackbarConfigurator
} from '@hypotenuse/common/src/utils/Snackbar'
import { ProductStateProvider } from './utils/context/ProductStateContext'
import { BeaconProvider } from '@hypotenuse/common/src/integrations/HelpScout'
import {
  ProtectedRouteWithFallbackComponent,
  ProtectedRouteWithRedirect
} from '@hypotenuse/common/src/utils/ProtectedRoute'
import UnauthorizedError from './components/UnauthorizedError'
import { Dimmer, Image, Loader } from 'semantic-ui-react'
import { Box } from '@material-ui/core'
import {
  AnalyticsConfigurator,
  AnalyticsProvider
} from '@hypotenuse/common/src/analytics/Analytics'
import {
  GOOGLE_ANALYTICS_TRACK_CODE,
  IS_PRODUCTION,
  LOGROCKET_APP_ID
} from './utils/Constants'
import ReloadPageOnNewLogin from '@hypotenuse/common/src/components/ReloadPageOnNewLogin'
import { PlatformType } from '../../common/src/utils/Interfaces'
import { SpinnerOnDimmer } from '@hypotenuse/common/src/components/Loaders'
import { GenerationAPIProvider } from '@hypotenuse/common/src/hooks/useGenerationAPI'
import ArticlePreviewRedirect from '@hypotenuse/common/src/pages/public/ArticlePreviewRedirect'
import { TakoInsufficientCreditsModal } from './components/TakoInsufficientCreditsModal'
import { marketingRoutes } from '@hypotenuse/common/src/pages/MarketingDashboard'
import { Papercups } from '@papercups-io/chat-widget'
// import TagManager from 'react-gtm-module'
// import { TagManagerArgs } from 'react-gtm-module/index'
// import { GtmEvents, GtmDataLayerTypes } from '@hypotenuse/common/src/integrations/GoogleTagManager/Interfaces'

const ShopifyDashboard = React.lazy(() => import('./pages/ShopifyDashboard'))
const Products = React.lazy(() => import('./pages/Products'))
const Account = React.lazy(() => import('./pages/Account'))
const PricingPlan = React.lazy(() => import('./pages/PricingPlan'))
const Support = React.lazy(() => import('./pages/Support'))
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'))
const Tutorial = React.lazy(() => import('./pages/Tutorial'))
const AdminPanel = React.lazy(() => import('./pages/AdminPanel'))
const Feedback = React.lazy(() => import('./pages/Feedback'))
const Interview = React.lazy(() => import('./pages/Interview'))
const Insights = React.lazy(() => import('./pages/insights/Insights'))
const AdvertisingText = React.lazy(
  () => import('@hypotenuse/common/src/pages/AdvertisingText')
)
const BlogGenerator = React.lazy(
  () => import('@hypotenuse/common/src/components/blog/BlogGenerator')
)
const BlogOverviewPage = React.lazy(
  () => import('@hypotenuse/common/src/pages/BlogOverviewPage')
)
const MarketingDashboard = React.lazy(
  () => import('@hypotenuse/common/src/pages/MarketingDashboard')
)
const ShopifyReferrals = React.lazy(
  () => import('@hypotenuse/common/src/pages/Referrals')
)
const FreeCredits = React.lazy(
  () => import('@hypotenuse/common/src/pages/FreeCredits')
)
const ReviewModal = React.lazy(() => import('./components/ReviewModal'))

// // Google Tag Manager initialization
// // See: https://github.com/alinemorelli/react-gtm
// const tagManagerArgs: TagManagerArgs = {
//   gtmId: GTM_CONTAINER_ID,
//   dataLayerName: GtmDataLayerTypes.GTM_SUBSCRIPTION_LAYER,
//   events: {
//     planChange: GtmEvents.GTM_TAKO_SUBSCRIPTION
//   }
// }

// try {
//   TagManager.initialize(tagManagerArgs)
// } catch (e) {// App should not break if TagManager fails
//   console.warn("Tag Manager not initialized properly")
// }

interface State {
  user?: User
  shopDisplay?: ShopDisplayDataAndStaffResponse
  productSyncStatus: ProductSyncStatus
  hlp: HelpHero
}

interface Props extends RouteComponentProps {}

class App extends React.Component<Props, State> {
  productStatusPoller?: NodeJS.Timeout
  detectedProductLanguagePoller?: NodeJS.Timeout
  state: State = {
    productSyncStatus: {
      currentCount: 0,
      totalCount: 0,
      status: 'not-loaded',
      message: ''
    },
    hlp: initHelpHero('zYiL6odmzMZ')
  }

  componentDidMount = async () => {
    await this.fetchUser()
    this.fetchShopData()
    this.pollProductSyncStatus()
  }

  redirectToLogin = () => {
    window.location.href = '/unauthorized'
  }

  fetchUser = async (callback?: Function) => {
    let username = ''
    return apiClient
      .get('/shopify/user/me')
      .then((response) => {
        username = response.data.username
        if (username === 'undefined') {
          //redirect to login page
          Analytics.trackEvent('User', 'Redirected to Login Page')
          this.redirectToLogin()
        } else {
          this.setState((prevState: State) => {
            // Do not update completedToursIds if user has completed tutorial before.
            const prevUserState = prevState.user
            if (prevUserState && getIsTutorialComplete(prevUserState)) {
              return {
                ...prevState,
                user: {
                  ...response.data,
                  completedToursIds: prevUserState.completedToursIds
                }
              }
            }
            return { ...prevState, user: response.data }
          })
          username = response.data.username
          handleTrackUserSession()
          // Identify HelpHero events to the user
          this.state.hlp.identify(username)
        }
      })
      .catch(
        ApiErrorCatcher((error) => {
          console.log(error)
          // Set HelpHero to be anonymous if there is an error
          this.state.hlp.anonymous()
        })
      )
      .finally(() => {
        // Identify Sentry events to user and set the severity level
        Sentry.configureScope(function (scope) {
          // Users that are not authenticated cannot be fetched, and are expected
          // to fail in subsequent calls to our backend, so we set them as warning.
          scope.setUser({ id: username })
          scope.setLevel(username ? 'error' : 'warning')
        })
        if (callback !== undefined) {
          callback()
        }
      })
  }
  fetchShopData = () => {
    apiClient
      .get('/shopify/shop')
      .then((resp) => {
        const shopDisplay: ShopDisplayDataAndStaffResponse = resp.data
        this.setState({ shopDisplay })
      })
      .catch(
        ApiErrorCatcher((error) => {
          console.log(error)
        })
      )
  }
  importProducts = () => {
    this.setIsImporting()
    return apiClient
      .get('/shopify/import')
      .then((resp) => {
        console.log(resp.data)
        Analytics.trackEvent('User', 'Importing products')
      })
      .catch(
        ApiErrorCatcher((err) => {
          console.log(err)
          this.setImportError()
          Sentry.captureMessage('Fetch products failed', 'error')
        })
      )
      .finally(() => this.pollProductSyncStatus())
  }
  fetchProductSyncStatus = () => {
    apiClient
      .get('/shopify/product_sync_status')
      .then((resp) => {
        if (resp.status < 400) {
          console.log(resp.data)
          this.setState({ productSyncStatus: resp.data.productSyncStatus })
        } else if (resp.status === 429) {
          console.log('Too many requests to Shopify')
        } else {
          this.setImportError()
        }
      })
      .catch(
        ApiErrorCatcher((err) => {
          console.log(err)
          this.setImportError()
          Sentry.captureMessage('Fetch import status failed', 'error')
        })
      )
  }
  pollProductSyncStatus = () => {
    if (!this.productStatusPoller) {
      // Poller has not been created
      // fetchProductSyncStatus here first because setInterval executes after delay
      this.fetchProductSyncStatus()
      this.productStatusPoller = setInterval(() => {
        console.log('---- Polling import status ----')
        this.fetchProductSyncStatus()
        this.pollProductSyncStatus()
      }, 3000)
    } else {
      // Poller has already been created
      if (this.state.productSyncStatus.status !== 'loading') {
        // Stop polling once load status is no longer loading
        clearInterval(this.productStatusPoller)
        this.productStatusPoller = undefined
        this.fetchUser()
      }
    }
  }
  setIsImporting = () => {
    this.setState((prevState: State) => {
      return {
        ...prevState,
        productSyncStatus: {
          ...prevState.productSyncStatus,
          status: 'loading',
          currentCount: undefined,
          totalCount: undefined,
          hasShopifyProducts: undefined,
          hasInternalProducts: undefined
        }
      }
    })
  }
  setProductSyncStatus = (productSyncStatus: ProductSyncStatus) => {
    this.setState({
      productSyncStatus: productSyncStatus
    })
  }
  setImportError = () => {
    this.setState((prevState: State) => {
      return {
        productSyncStatus: { ...prevState.productSyncStatus, status: 'error' }
      }
    })
  }
  setTutorialComplete = () => {
    let completedToursIds = this.state.user?.completedToursIds ?? []
    if (completedToursIds.indexOf(SHOPIFY_HELPHERO_TUTORIAL_TOUR.id) === -1) {
      completedToursIds.push(SHOPIFY_HELPHERO_TUTORIAL_TOUR.id)
    }
    Analytics.trackEvent('User', 'Completed tutorial')
    this.setState(({ user }) => {
      if (user) {
        return {
          user: {
            ...user,
            completedToursIds: completedToursIds
          }
        }
      } else {
        return { user: undefined }
      }
    })
  }
  setDetectedProductLanguageConfig = (
    config: DetectedProductLanguageConfig
  ) => {
    this.setState(({ user }) => {
      if (user) {
        return {
          user: {
            ...user,
            service_configs: {
              ...user.service_configs,
              detected_language: config
            }
          }
        }
      } else {
        return { user: undefined }
      }
    })
  }

  renderSwitchFallback = () => {
    Analytics.trackEvent('user', 'Error Encountered')
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '70vh'
        }}
      >
        <UnexpectedError />
      </div>
    )
  }
  render = () => {
    const { user, shopDisplay, productSyncStatus, hlp } = this.state
    const onRateLimitExceeded = () => {
      // Rate limit exceeded: surface error to user
      snackbar.show(
        'Uh oh! It looks like you’ve hit the limit of your free trial. Please subscribe to generate more.',
        {
          variant: 'warning'
        }
      )
    }
    const hasAccess = user ? user.hasAccess : false
    const isTutorialComplete = user ? getIsTutorialComplete(user) : true
    const isInTutorial = this.props.location.pathname === '/tutorial'
    const shouldPopupChat = !getHasSeenPapercups() && !isInTutorial

    return (
      <QueryParamProvider ReactRouterRoute={Route}>
        <AnalyticsProvider
          username={user?.username}
          disableTracking={!IS_PRODUCTION}
          logRocketAppId={LOGROCKET_APP_ID}
          logRocketExcludedUsernamePatterns={[
            /thethirdphase\.myshopify\.com/,
            /thethirdphase3\.myshopify\.com/,
            /thethirdphase5\.myshopify\.com/,
            /basilporkrice\.myshopify\.com/,
            /tako-user\.myshopify\.com/
          ]}
          googleAnalyticsTrackingCode={GOOGLE_ANALYTICS_TRACK_CODE}
        >
          <AnalyticsConfigurator />
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              preventDuplicate
            >
              <BeaconProvider
                beaconId={HELPSCOUT_BEACON_ID}
                disableBeaconLinks={DISABLE_BEACON_LINKS}
              >
                <GenerationAPIProvider
                  onRateLimitExceeded={onRateLimitExceeded}
                >
                  <SnackbarConfigurator openChat={Papercups.open} />
                  <div data-testid="appPage" style={{ display: 'none' }} />
                  <ReloadPageOnNewLogin username={user?.username} />
                  <TakoInsufficientCreditsModal />
                  <Suspense fallback={<SpinnerOnDimmer />}>
                    <Switch>
                      <Route
                        path="/unauthorized"
                        exact
                        component={UnauthorizedError}
                      />
                      <ProtectedRouteWithRedirect
                        path="/article-preview"
                        component={ArticlePreviewRedirect}
                        hasAccess={TAKO_ENABLE_ARTICLE_PUBLIC_SHARING}
                        redirectUrl={'/home'}
                      />
                      <ProtectedRouteWithFallbackComponent
                        // This component only shows once it has passed shopify authentication on the backend
                        fallbackComponent={
                          <>
                            {user ? (
                              // User has been fetched but user.hasAccess == false
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100vh"
                              >
                                <Image
                                  src="/assets/authentication_needed.svg"
                                  size="large"
                                />
                                <h2
                                  style={{
                                    textAlign: 'center',
                                    padding: '10px'
                                  }}
                                >
                                  Sorry! Your shopify account does not have
                                  access to Tako yet.
                                </h2>
                              </Box>
                            ) : (
                              // Fetching user to load app (hasAccess === false)
                              // This condition has the assumption that /shopify/user/me is not an unauthorized request
                              // If it is unauthorized and a user fails to be fetched, the 401 error will be intercepted to
                              // redirect the user to /unauthorized to login to shopify instead of seeing this component
                              <Dimmer
                                active
                                inverted
                                style={{ position: 'fixed' }}
                              >
                                <Loader inverted size="large">
                                  <h2
                                    style={{
                                      textAlign: 'center',
                                      padding: '10px'
                                    }}
                                  >
                                    Loading Tako...
                                  </h2>
                                </Loader>
                              </Dimmer>
                            )}
                          </>
                        }
                        hasAccess={hasAccess}
                      >
                        <SlimDrawer
                          user={user}
                          productSyncStatus={productSyncStatus}
                          importProducts={this.importProducts}
                          fetchImportStatus={this.fetchProductSyncStatus}
                          hlp={hlp}
                          tour={SHOPIFY_HELPHERO_TUTORIAL_TOUR}
                        >
                          {/* Showing modal in tutorial will overlay helphero and lead to undesired behavior */}
                          {user && window.location.pathname !== '/tutorial' && (
                            <ReviewModal user={user} />
                          )}
                          <ProductStateProvider>
                            <Switch>
                              <Route
                                path={['/', '/home']}
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <ShopifyDashboard
                                      shopDisplay={shopDisplay}
                                      user={user}
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path={'/descriptions'}
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <Products
                                      user={user}
                                      fetchUser={this.fetchUser}
                                      productSyncStatus={productSyncStatus}
                                      isTutorialComplete={isTutorialComplete}
                                      handleImportProducts={this.importProducts}
                                      handleUpdateProductSyncStatus={
                                        this.setProductSyncStatus
                                      }
                                      setWasShownDetectedLanguageConfig={() =>
                                        this.setDetectedProductLanguageConfig({
                                          was_detected: true,
                                          was_shown: true,
                                          language:
                                            user?.service_configs
                                              .detected_language.language
                                        })
                                      }
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/blogs"
                                exact
                                render={(
                                  props: RouteComponentProps<{
                                    [x: string]: string | undefined
                                  }>
                                ) => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <BlogOverviewPage {...props} />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/blog-post"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <BlogGenerator
                                      enableShare={
                                        TAKO_ENABLE_ARTICLE_PUBLIC_SHARING
                                      }
                                      userFeatureFlags={
                                        user?.service_configs?.feature_flags
                                      }
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/insights/:productId?"
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <Insights shopDisplay={shopDisplay} />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/account"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <Account shopDisplay={shopDisplay} />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <ProtectedRouteWithRedirect
                                hasAccess={
                                  user?.service_configs?.interview.show
                                }
                                path="/interview"
                                redirectUrl="/home"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <Interview
                                      shopDisplay={shopDisplay}
                                      interviewConfig={
                                        user?.service_configs?.interview
                                      }
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <ProtectedRouteWithRedirect
                                hasAccess={user?.admin}
                                path="/admin"
                                redirectUrl={'/home'}
                                exact
                                render={() => <AdminPanel />}
                              />
                              <Route
                                path="/pricing"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <PricingPlan
                                      fetchUser={this.fetchUser}
                                      user={user}
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/advertising"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <MarketingDashboard
                                      onOpenChat={Papercups.open}
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              {adTextTabs.map((tab) => {
                                return (
                                  <Route
                                    key={tab.key}
                                    path={
                                      marketingRoutes[tab.key as AdTextType]
                                    }
                                    exact
                                    render={() => (
                                      <Sentry.ErrorBoundary
                                        fallback={this.renderSwitchFallback}
                                      >
                                        <AdvertisingText
                                          username={user?.username}
                                          refreshUser={this.fetchUser}
                                          platformType={PlatformType.shopify}
                                          adTextType={tab.key}
                                          key={tab.key}
                                        />
                                      </Sentry.ErrorBoundary>
                                    )}
                                  />
                                )
                              })}
                              <Route
                                path="/support"
                                exact
                                component={Support}
                              />
                              <Route
                                path="/tutorial"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    <Tutorial
                                      isTutorialComplete={isTutorialComplete}
                                      hlp={hlp}
                                      setTutorialComplete={
                                        this.setTutorialComplete
                                      }
                                      tour={SHOPIFY_HELPHERO_TUTORIAL_TOUR}
                                    />
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/free-credits"
                                exact
                                render={() => (
                                  <Sentry.ErrorBoundary
                                    fallback={this.renderSwitchFallback}
                                  >
                                    {TAKO_ENABLE_NEW_REFERRALS ? (
                                      <FreeCredits
                                        enableLinkedInShare={
                                          user?.service_configs
                                            .dashboard_incentive_options
                                            ?.linkedin_post
                                        }
                                      />
                                    ) : (
                                      <ShopifyReferrals />
                                    )}
                                  </Sentry.ErrorBoundary>
                                )}
                              />
                              <Route
                                path="/feedback"
                                exact
                                component={Feedback}
                              />
                              <Route component={PageNotFound} />
                            </Switch>
                          </ProductStateProvider>
                          <ChatSupport
                            user={user}
                            customer={shopDisplay}
                            shouldPopupChat={shouldPopupChat}
                          />
                        </SlimDrawer>
                      </ProtectedRouteWithFallbackComponent>
                    </Switch>
                  </Suspense>
                </GenerationAPIProvider>
              </BeaconProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </AnalyticsProvider>
      </QueryParamProvider>
    )
  }
}

export default withRouter(App)
