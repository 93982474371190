/**
 * Constants common to all apps
 */

// Environment STAGE
export const STAGE = process.env.REACT_APP_STAGE

// ===== LOCALSTORAGE KEYS =====
//TODO: Move these keys used for local storage here into a localStorageKeys object for better code quality
export const HAS_SEEN_PAPERCUPS = 'hasSeenPapercups'
export const IS_HIDE_EMPTY_METADATA_ROWS = 'isHideEmptyMetadataRows'
export const PREMIUM_HAS_CONTACTED_US = 'premiumHasContactedUs'
export const IMPORT_CONTACT_US = 'importContactUs'
export const ENTERPRISE_PLAN_CONTACT_US = 'enterprisePlanContactUs'
export const IS_FIRST_MARK_AS_DONE = 'isFirstMarkAsDone'
export const PREMIUM_DONE_TUTORIAL_BEFORE = 'premiumDoneTutorialBefore'
export const PREMIUM_SHOW_BANNER_NAV = 'premiumShowBannerNav'
export const HAS_SUBMITTED_LINKEDIN_POST_LINK = 'hasSubmittedLinkedinPostLink'
export const IMAGE_GENERATION_LOADING_TIME = 'imageGenerationLoadingTime'

// ===== PAPERCUPS =====
export const PAPERCUPS_POPUP_DELAY = 180000

// ===== WRITER STYLES ADMIN =====
export const MAX_TEMPERATURE = 2
export const MAX_TOKENS = 900
export const MAX_COUNTS = 9

// ===== Server =====
// TODO: Server URL should be configured on a per-app basis.
//  Ideally we would refactor the apiClient builder to accept this
//  as a parameter and specify it in each individual app's config.
export const SERVER = process.env.REACT_APP_SERVER || ''

// ===== Colors =====
export const ERROR_COLOR = '#ee1a31' // Red
export const BLOG_PREFIX_COLOR = '#4062C2'

// ===== Semantic UI Popup Overflow Prevention
// Needed when parent has overflow property: https://github.com/Semantic-Org/Semantic-UI-React/issues/3725
export const SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS = {
  preventOverflow: {
    boundariesElement: 'offsetParent'
  }
}

// ===== Text to Image Generation constants =====
export const TEXT_TO_IMAGE_PROMPT_MAX_CHARS = 400

// ===== Blog constants =====
export const BRAND_BACKGROUND_MAX_LIMIT = 500
export const TOPIC_CHAR_MAX_LIMIT = 500
/**
 * Max limit of keywords a blog can have
 */
export const KEYWORDS_MAX_LIMIT = 5
/**
 * Max character limit of all keywords
 */
export const TOTAL_KEYWORDS_CHAR_MAX_LIMIT = 200
export const TITLE_CHAR_MAX_LIMIT = 200
export const TARGET_AUDIENCE_MAX_CHARS = 200
export const PRODUCT_DETAILS_MAX_CHARS = 1000
export const OUTLINE_CHAR_MAX_LIMIT = 200
export const NON_SELECTED_INDEX = -1
export const ORIGINAL_TITLE_INDEX = -2
/**
 * Minimum expected number of paragraph outlines for a blog post.
 */
export const MIN_EXPECTED_OUTLINES = 5
/**
 * Maximum number of outlines allowed per blog post
 */
export const BLOG_MAX_OUTLINES = 11
/**
 * Max number of 'details' that can be added for each paragraph outline.
 */
export const MAX_OUTLINE_DETAILS = 5
export const OUTLINE_DETAILS_MAX_LIMIT = 200
/**
 * Minimum number of characters required for select & rewrite
 */
export const BLOG_MIN_REWRITE_CHARS = 30
/**
 * Maximum number of characters allowed for select & rewrite
 */
export const BLOG_MAX_REWRITE_CHARS = 300

// ===== Limits for Advertising Copy =====
/**
 * Number of characters soft limit for google ads headlines
 */
export const GOOGLE_HEADLINE_CHAR_MAX_LIMIT = 30
/**
 * Number of characters soft limit for google ads descriptions
 */
export const GOOGLE_DESCRIPTION_CHAR_MAX_LIMIT = 90
/**
 * Number of characters soft limit for linkedin post ads descriptions
 */
export const LINKEDIN_POST_CHAR_MAX_LIMIT = 3000
/**
 * Default ad tone
 */
export const DEFAULT_AD_TONE = 'Conversational'

/**
 * The URL at which blog previews are hosted.
 */
export const BLOG_PREVIEW_BASE_PATH =
  process.env.REACT_APP_BLOG_PREVIEW_BASE_PATH

/**
 * The URL at which generated image previews are hosted.
 */
export const GENERATED_IMAGE_PREVIEW_BASE_PATH =
  process.env.REACT_APP_GENERATED_IMAGE_PREVIEW_BASE_PATH

// ===== Limits for Dashboard Incentives =====

export const DEFAULT_REFERRAL_CREDITS = 50
export const DEFAULT_LINK_SHARING_CREDITS = 30

// ===== Months =====
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

// ===== Sizes =====
export enum SIZES {
  small = 'sm',
  medium = 'md',
  large = 'lg'
}

export const DEBOUNCED_TIMING = 330

// ====== Feature Flags ======
// TODO: All feature flags moving forward should use GlobalFeatureFlags that can be enabled in DB.
/**
 * Blog editor bubble menu containing an option to rewrite selected content.
 */
export const ENABLE_BLOG_BUBBLE_MENU = false
/**
 * Blog editor floating menu containing an option to write more content.
 */
export const ENABLE_FLOATING_MENU = true
/**
 * Show the different tabs in the marketing dashboard.
 */
export const SHOW_MARKETING_TABS = false

// Other frontend constants
export const DESKTOP_DRAWER_WIDTH_OPEN = 248
export const DESKTOP_DRAWER_WIDTH_CLOSE = 68
export const MOBILE_DRAWER_WIDTH_OPEN = 280
export const MOBILE_DRAWER_WIDTH_CLOSE = 0
// In case we want to not close drawer on default
// export const pathsWithClosedDrawer = [
//   'blog-post',
//   'advertising/',
//   'catalog',
//   'image-generation'
// ]
export const SHOW_REFERRAL_SIDEBAR_LINK = false
export const ICON_SIZE = '20px'
export const ICON_STROKE = '2px'
