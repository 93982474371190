import React from 'react'
import {
  Box,
  Dialog,
  Divider,
  Fade,
  FadeProps,
  IconButton,
  IconButtonProps
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog/Dialog'
import Close from '@material-ui/icons/Close'

export interface SimpleDialogProps extends Omit<DialogProps, 'onClose'> {
  /**
   * Callback to close the modal. If provided, the close button will be displayed.
   * This prop is overridden from DialogProps to allow calling it directly without a click event.
   */
  onClose?: () => void // TODO: This prop override is unnecessary; can just passthrough from DialogProps
  topDivider?: boolean
  closeButtonSize?: IconButtonProps['size']
  disableTopBox?: boolean
}

const DialogTransition = React.forwardRef(function Transition(
  props: FadeProps,
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />
})

/**
 * A generic dialog modal with sensible defaults
 *
 * Uses a fade transition by default
 *
 * @param {SimpleDialogProps} props
 * @returns {JSX.Element}
 */
export const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
  const {
    title,
    // maxWidth defaults to false, which makes the dialog full width
    maxWidth = false,
    // topDivider defaults to true, adding a line at the top of the Dialog box
    topDivider = true,
    // closeButtonSize defaults to small size
    closeButtonSize = 'small',
    // Optional flag to remove top box from Dialog, defaults to False
    disableTopBox,
    onClose,
    children,
    ...dialogProps
  } = props
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      maxWidth={maxWidth}
      onClose={onClose}
      {...dialogProps}
    >
      {onClose && (
        <>
          {!disableTopBox && (
            <Box display="flex" justifyContent="flex-end" p={1}>
              <IconButton onClick={onClose} size={closeButtonSize}>
                <Close />
              </IconButton>
            </Box>
          )}
          {topDivider && <Divider light />}
        </>
      )}
      {children}
    </Dialog>
  )
}
