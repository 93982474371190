import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ChatWidget, Papercups } from '@papercups-io/chat-widget'
import { Storytime } from '@papercups-io/storytime'

import {
  PAPERCUPS_ACCOUNT_ID,
  PAPERCUPS_CHAT_TITLE,
  PAPERCUPS_CHAT_SUBTITLE,
  PAPERCUPS_CHAT_PLACEHOLDER,
  PAPERCUPS_CHAT_GREETING,
  PAPERCUPS_CHAT_COLOR,
  PAPERCUPS_BASE_URL
} from '../utils/Constants'
import { ShopDisplayDataAndStaffResponse, User } from '../utils/Interfaces'
import { PAPERCUPS_POPUP_DELAY } from '@hypotenuse/common/src/utils/Constants'
import { setHasSeenPapercups } from '@hypotenuse/common/src/utils/Functions'
import { trackCustomerSupportMessageReceived } from '@hypotenuse/common/src/api/Analytics'
import { CustomerMetadata } from '@papercups-io/chat-widget/dist/api'

interface Props {
  user?: User
  customer?: ShopDisplayDataAndStaffResponse
  shouldPopupChat?: boolean
}

export default function ChatSupport(props: Props) {
  const { customer, shouldPopupChat, user } = props
  const [isChatLoaded, setIsChatLoaded] = useState<boolean>(false)

  const isStorytimeInitialized = useRef(false)
  const customerObj: CustomerMetadata | undefined = useMemo(
    () =>
      customer && user
        ? {
            name: customer.domain,
            email: customer.email,
            external_id: customer.domain,
            metadata: {
              username: user.username,
              domain: customer.domain,
              last_name: customer.current_staff?.last_name,
              first_name: customer.current_staff?.first_name,
              email: customer.current_staff?.email
            }
          }
        : undefined,
    [user, customer]
  )

  useEffect(() => {
    if (customerObj && !isStorytimeInitialized.current) {
      Storytime.init({
        accountId: PAPERCUPS_ACCOUNT_ID,
        baseUrl: PAPERCUPS_BASE_URL,
        customer: customerObj
      })
      isStorytimeInitialized.current = true
    }
  }, [customerObj])

  const popupTimeoutRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    // Set a timer to popup chat IF shouldPopupChat is true and a timer doesn't already exist.
    if (shouldPopupChat) {
      if (isChatLoaded && !popupTimeoutRef.current) {
        popupTimeoutRef.current = setTimeout(
          Papercups.open,
          PAPERCUPS_POPUP_DELAY
        )
      }
    } else if (popupTimeoutRef.current) {
      // If shouldPopupChat becomes false and there exists a timer to popup chat, clear it.
      clearTimeout(popupTimeoutRef.current)
      popupTimeoutRef.current = undefined
    }
  }, [isChatLoaded, shouldPopupChat])

  if (!customer) return null

  return (
    <ChatWidget
      accountId={PAPERCUPS_ACCOUNT_ID}
      title={PAPERCUPS_CHAT_TITLE}
      subtitle={PAPERCUPS_CHAT_SUBTITLE}
      newMessagePlaceholder={PAPERCUPS_CHAT_PLACEHOLDER}
      primaryColor={PAPERCUPS_CHAT_COLOR}
      greeting={PAPERCUPS_CHAT_GREETING}
      baseUrl={PAPERCUPS_BASE_URL}
      customer={customerObj}
      onChatOpened={() => setHasSeenPapercups(true)}
      onChatLoaded={() => setIsChatLoaded(true)}
      onMessageSent={(message) => trackCustomerSupportMessageReceived(message)}
    />
  )
}
