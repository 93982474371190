import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useLocation, useHistory } from 'react-router-dom'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import StarRateIcon from '@material-ui/icons/StarRate'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import BookIcon from '@material-ui/icons/Book'
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined'
import HomeIcon from '@material-ui/icons/Home'
import { ProductSyncStatus, User } from '../utils/Interfaces'
import { Tour } from '@hypotenuse/common/src/interfaces/Tutorial'
import { Box, Button, Chip, LinearProgress } from '@material-ui/core'
import { LOGO_SHORT_SRC } from '../utils/Constants'
import TakoSidebarLogo from '../../public/logos/TakoSidebarLogoColored.svg'
import { HelpHero } from 'helphero'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FastForward from '@material-ui/icons/FastForward'
import MenuBookOutlined from '@material-ui/icons/MenuBookOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import { PlanCreditProvisionType } from '../../../common/src/utils/Interfaces'
import { ImportProductsButton } from './ImportProductsButton'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'

interface Props {
  children: any
  user?: User
  importProducts: () => void
  fetchImportStatus: Function
  tour: Tour
  hlp: HelpHero
  productSyncStatus: ProductSyncStatus
}

const drawerWidth = 190

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    hide: {
      display: 'none'
    },
    redirectOnClick: {
      cursor: 'pointer'
    },
    toolbarClosedLogo: {
      maxWidth: 24,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    toolbarOpenLogo: {
      maxWidth: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    // Side bar
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(6) + 1,
      [theme.breakpoints.up('sm')]: {
        width: '52px'
      }
    },
    drawerListItemIcon: {
      minWidth: '36px'
    },
    drawerListItem: {
      '&:hover': {
        color: 'var(--themeCol1-primary-contrast-color)'
      }
    },
    drawerListItemActive: {
      borderRight: '3px',
      borderColor: 'var(--themeCol1-primary-color)',
      borderStyle: 'solid',
      color: 'var(--themeCol1-primary-color)',
      '&:hover': {
        color: 'var(--themeCol1-primary-contrast-color)'
      }
    },

    // Navigation Bar
    appBar: {
      backgroundColor: 'var(--themeCol1-bg-color)',
      color: 'black',
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer - 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButtonClosed: {
      marginLeft: 30
    },
    mobileMenuButtonClosed: {
      margin: 0,
      position: 'fixed',
      bottom: '20px',
      left: '20px',
      backgroundColor: '#576EC7', // eyedropper to same as papercups
      borderRadius: '30%',
      padding: '10px',
      fontSize: '30px' // controls icon size
    },
    menuButtonOpen: {
      marginLeft: -20
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    toolbarOpen: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    toolbarGrow: {
      flexGrow: 1
    },
    toolbarRightSectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex'
      }
    },
    toolbarRightSectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    welcomeUserText: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    verticalAlignCenter: {
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    // Actual content
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      minWidth: 0,
      maxWidth: '100%'
    },
    syncButtonInner: {
      display: 'flex',
      alignItems: 'center'
    },
    syncButtonInnerIcon: {
      marginRight: 5,
      fontSize: 16
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  })
)

export const ExtraSmallChip = withStyles({
  root: {
    fontWeight: 700,
    height: '14px',
    fontSize: '8px'
  }
})(Chip)

export const checkIfStringContainsVoucher = (
  voucherDisplay: string,
  creditValue: number
) => {
  if (voucherDisplay.match(/voucher/i)) {
    return `a $${creditValue} ${voucherDisplay} `
  } else {
    return `${creditValue} ${voucherDisplay} `
  }
}

export default function SlimDrawer(props: Props) {
  const feature = {
    interview: props.user?.service_configs.interview
  }

  const isMobile = useIsMobile()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const [open, setOpen] = React.useState(true)

  const isImporting = () => {
    return (
      props.productSyncStatus.status === 'loading' &&
      props.productSyncStatus.currentCount !== undefined &&
      props.productSyncStatus.totalCount !== undefined
    )
  }

  // hook to close side panel by default
  const closedByDefault = isMobile
  useEffect(() => {
    if (closedByDefault) handleDrawerClose()
  }, [closedByDefault])

  const redirectToPath = (path: string) => {
    history.push(path)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const computeLoadPercentage = () => {
    if (props.productSyncStatus.totalCount === 0) {
      return 0
    }
    return (
      (100 * (props.productSyncStatus.currentCount ?? 0)) /
      (props.productSyncStatus.totalCount ?? 0)
    )
  }

  const renderInterviewDrawer = () => {
    return [
      {
        pathname: '/interview',
        text: feature.interview?.credits
          ? feature.interview?.voucherDisplay
            ? `Get ${checkIfStringContainsVoucher(
                feature.interview.voucherDisplay,
                feature.interview.credits
              )}`
            : `Get ${feature.interview.credits} Free Credits`
          : 'Feedback Interview',
        type: 'interview',
        icon: <StarRateIcon style={{ fill: '#ffac33', fontSize: 21 }} />
      }
    ].map((obj: any) => {
      return (
        <div>
          <ListItem
            key={obj.text}
            to={obj.pathname}
            className={clsx(classes.drawerListItem, {
              [classes.drawerListItemActive]: location.pathname === obj.pathname
            })}
            component={Link}
            button
            disabled={props.user ? !props.user.hasAccess : true}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box style={{ whiteSpace: 'normal' }}>
                {open && <ListItemText primary={<strong>{obj.text}</strong>} />}
              </Box>
              <Box>{obj.icon}</Box>
            </Box>
          </ListItem>
        </div>
      )
    })
  }

  const renderCredits = () => {
    return (
      <p>
        Credits:{' '}
        {props.user && (
          <strong
            className={clsx(classes.redirectOnClick, 'primaryFontColor')}
            onClick={() => {
              redirectToPath('/pricing')
            }}
          >
            {props.user.creditsLeft}
          </strong>
        )}
        {props.user &&
          props.user.creditProvisionType === PlanCreditProvisionType.reset &&
          !props.user.inTrial && (
            <strong
              className={clsx(classes.redirectOnClick, 'primaryFontColor')}
              onClick={() => {
                redirectToPath('/pricing')
              }}
            >
              {` / ${props.user.monthlyCreditAmount}`}
            </strong>
          )}
      </p>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && !isMobile
        })}
        id="topBar"
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={clsx({
              [isMobile ? 'none' : classes.menuButtonOpen]: open,
              [isMobile
                ? classes.mobileMenuButtonClosed
                : classes.menuButtonClosed]: !open
            })}
          >
            {open ? (
              isMobile ? undefined : (
                <ChevronLeft />
              )
            ) : isMobile ? (
              <MenuIcon fontSize="inherit" style={{ fill: '#ffffff' }} /> // white, else light purple ##4218D1'
            ) : (
              <ChevronRight />
            )}
          </IconButton>
          <div className={classes.toolbarGrow} />
          {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
          {/*  <Badge badgeContent={17} color="secondary">*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          <div className={classes.toolbarRightSectionDesktop}>
            {props.user?.admin && (
              <Button
                component={Link}
                to="/admin"
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
              >
                Admin Panel
              </Button>
            )}
          </div>
          <ImportProductsButton
            productSyncStatus={props.productSyncStatus}
            onImportProducts={props.importProducts}
          />
          <div
            className={classes.verticalAlignCenter}
            style={{ marginLeft: 20, marginRight: 20 }}
          >
            {renderCredits()}
          </div>
          <div className={classes.welcomeUserText}>
            <p>
              Welcome,{' '}
              <strong
                className={clsx(classes.redirectOnClick, 'primaryFontColor')}
                onClick={() => {
                  redirectToPath('/account')
                  if (isMobile) handleDrawerClose()
                }}
              >
                {props.user && `${props.user.username}`}
              </strong>
            </p>
          </div>
        </Toolbar>
        {isImporting() ? (
          <LinearProgress
            value={computeLoadPercentage()}
            variant="determinate"
          />
        ) : (
          <div />
        )}
      </AppBar>

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbar}>
          <img
            src={LOGO_SHORT_SRC}
            alt="logo"
            onClick={() => {
              redirectToPath('/home')
              if (isMobile) handleDrawerClose()
            }}
            className={clsx(
              classes.toolbarClosedLogo,
              {
                [classes.hide]: open
              },
              classes.redirectOnClick
            )}
          />
          <img
            src={TakoSidebarLogo}
            alt="logo"
            onClick={() => {
              redirectToPath('/home')
              if (isMobile) handleDrawerClose()
            }}
            className={clsx(
              classes.toolbarOpenLogo,
              {
                [classes.hide]: !open
              },
              classes.redirectOnClick
            )}
          />
        </div>
        <List>
          {[
            {
              pathname: '/home',
              text: 'Home',
              icon: <HomeIcon />
            },
            {
              pathname: '/descriptions',
              text: 'Descriptions',
              icon: <LocalOfferOutlinedIcon />
            },
            ...(props.user?.service_configs.blog_gen_config?.show
              ? [
                  {
                    pathname: '/blogs',
                    text: 'Blog Posts',
                    icon: <BookIcon />,
                    beta: true
                  }
                ]
              : []),
            ...(props.user?.service_configs.blog_gen_config?.show // Show if blogs are also shown
              ? [
                  {
                    pathname: '/advertising',
                    text: 'Marketing Copy',
                    icon: <FastForward />
                  }
                ]
              : []),
            ...(props.user?.service_configs.feature_flags?.enable_insights_page
              ? [
                  {
                    pathname: '/insights',
                    text: 'Insights',
                    icon: <TimelineOutlinedIcon />
                  }
                ]
              : [])
          ].map((obj: any) => (
            <ListItem
              key={obj.text}
              to={obj.pathname}
              className={clsx(classes.drawerListItem, {
                [classes.drawerListItemActive]:
                  location.pathname === obj.pathname
              })}
              onClick={() => {
                if (isMobile) handleDrawerClose()
              }}
              component={Link}
              button
              disabled={props.user ? !props.user.hasAccess : true}
            >
              <ListItemIcon className={classes.drawerListItemIcon}>
                {obj.icon}
              </ListItemIcon>
              {obj.beta ? (
                <ListItemText
                  primary={
                    <div className={classes.row}>
                      <span>{obj.text}</span>
                      <ExtraSmallChip
                        label="BETA"
                        size="small"
                        color="primary"
                      />
                    </div>
                  }
                />
              ) : (
                <ListItemText primary={obj.text} />
              )}
            </ListItem>
          ))}
          {(feature.interview?.show ?? false) && renderInterviewDrawer()}
        </List>
        <Divider />
        <List style={{ height: '100%' }}>
          {[
            {
              pathname: '/account',
              text: 'Account',
              icon: <AccountCircleOutlinedIcon />,
              onClick: () => {
                if (isMobile) handleDrawerClose()
              }
            },
            {
              pathname: '/pricing',
              text: 'Pricing Plans',
              icon: <MonetizationOnOutlinedIcon />,
              onClick: () => {
                if (isMobile) handleDrawerClose()
              }
            }, // TODO: Change Icon
            {
              pathname: '/free-credits',
              text: 'Refer A Friend',
              icon: <GroupOutlinedIcon />,
              onClick: () => {
                if (isMobile) handleDrawerClose()
              }
            }, // TODO: Change Icon
            {
              pathname: '/tutorial',
              text: 'Tutorial',
              icon: <MenuBookOutlined />,
              onClick: () => {
                props.hlp.startTour(props.tour.id)
                if (isMobile) handleDrawerClose()
              }
            },
            {
              pathname: '#',
              text: 'FAQ',
              icon: <ContactSupportOutlinedIcon />,
              onClick: () => {
                window.open('https://help.hellotako.com', '_blank')
                if (isMobile) handleDrawerClose()
              }
            },
            {
              pathname: '/feedback',
              text: 'Feedback',
              icon: <PostAddOutlinedIcon />,
              onClick: () => {
                if (isMobile) handleDrawerClose()
              }
            }
          ].map((obj: any) => {
            return (
              <ListItem
                key={obj.text}
                to={obj.pathname}
                className={clsx(classes.drawerListItem, {
                  [classes.drawerListItemActive]:
                    location.pathname === obj.pathname
                })}
                component={Link}
                button
                onClick={obj.onClick}
              >
                <ListItemIcon className={classes.drawerListItemIcon}>
                  {obj.icon}
                </ListItemIcon>
                <ListItemText primary={obj.text} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}
