import React, { useEffect } from 'react'
import useBroadcast from '../hooks/useBroadcast'
import { showWithSnackbar } from '../utils/Functions'

export interface Props {
  /**
   * The user logged in on the current window
   */
  username?: string
}

/**
 * Listens for changes to the logged in user on all open windows of the application.
 * When a different user is detected in a window, all other windows are refreshed.
 */
const ReloadPageOnNewLogin: React.FC<Props> = (props) => {
  const { username: currentTabUsername } = props

  const onNewLogin = () => {
    showWithSnackbar(
      'New login detected. Reload page to refresh your session.',
      {
        onExit: () => window.location.reload(),
        onClick: () => window.location.reload(),
        buttonText: 'Reload',
        snackbarOptions: {
          variant: 'info',
          autoHideDuration: 20000,
          preventDuplicate: true
        }
      }
    )
  }

  const { postMessage } = useBroadcast<string | undefined>(
    'username',
    (newUsername) => {
      // If a different username is seen to have logged in on another window, trigger the callback.
      if (
        newUsername &&
        currentTabUsername &&
        newUsername !== currentTabUsername
      ) {
        console.warn(
          `New login detected: ${currentTabUsername} -> ${newUsername}`
        )
        onNewLogin()
      }
    }
  )

  // region Effects
  useEffect(() => {
    // When the user logged into the current tab changes (and on first load), notify all other windows.
    if (currentTabUsername) postMessage(currentTabUsername)
  }, [currentTabUsername])
  // endregion

  return null
}

export default ReloadPageOnNewLogin
