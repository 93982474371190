import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import { ReportType } from '../utils/Interfaces'
import { Description } from '@hypotenuse/common/src/interfaces/Products'

export const handleTrackUserSession = () => {
  apiClient
    .post('/analytics/session')
    .then((_resp) => {})
    .catch((error) => {
      console.log(error)
    })
}

export const handleTrackDescriptionEdit = (
  description: Description,
  productId: string
) => {
  return apiClient.post<void>('/analytics/product/description_edit', {
    description: description,
    product_id: productId
  })
}

export const handleTrackDescriptionCopy = (
  description: Description,
  productId: string
) => {
  return apiClient.post<void>('/analytics/product/description_copy', {
    description: description,
    product_id: productId
  })
}

export const handleTrackDescriptionPaste = (
  content: string,
  description: Description,
  productId: string
) => {
  return apiClient.post<void>(
    '/analytics/product/description_paste',
    {
      description: description,
      product_id: productId
    },
    {
      params: {
        content: content
      }
    }
  )
}

export const handleTrackCopyReviewTemplate = () => {
  return apiClient.post<void>('/analytics/copy_review_template')
}

export const handleTrackReviewPopupOpen = () => {
  return apiClient.post<void>('/analytics/review_popup_open')
}

export const handleTrackSettingsButtonClick = () => {
  return apiClient.post<void>('/analytics/settings')
}

// Currently, we download all events since the start of our Shopify App
export const handleDownloadMixpanelData = (report_type: ReportType) => {
  const toDate = new Date()
  const fromDate = new Date(2020, 12, 1) // 12 December 2020

  // To Date can't be today's date, so need to minus 1 day from current date
  toDate.setDate(toDate.getDate() - 1)

  return apiClient
    .get<string>('/analytics/mixpanel_data', {
      params: {
        from_date: fromDate.toISOString().slice(0, 10), // Remove Time
        to_date: toDate.toISOString().slice(0, 10),
        report_type: report_type
      }
    })
    .then((resp) => resp.data)
}
