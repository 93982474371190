import React, { useContext, useState } from 'react'
import {
  apiGenerateContent,
  apiRewriteText,
  apiWriteMore,
  WriteMoreGenerationContext
} from '../api/LanguageModel'
import { Subtract } from 'utility-types'
import axios, { AxiosResponse } from 'axios'
import { BlogStepper } from '../interfaces/Blog'
import { AdTextType, generationTypeMap } from '../interfaces/AdvertisingText'
import { apiClient } from '../utils/ApiClient'
import { ServerErrorType } from '../utils/Interfaces'
import { ContentGenerationType } from '../interfaces/ContentGeneration'
import { Description } from '../interfaces/Products'

// ===== Types & Interfaces =====
/**
 * This defines the type of the object returned by the useGenerationAPI hook.
 * NOTE: we have inconsistent naming for interfaces. Can consider abandoning the "I" prefix?
 * See https://softwareengineering.stackexchange.com/questions/117348/should-interface-names-begin-with-an-i-prefix
 */
export interface IGenerationAPIContext {
  /**
   * Rewrite a piece of text
   */
  rewriteText: (text: string) => Promise<string | undefined>
  // LM generation methods
  apiGenerateAdvertisingText: (
    productId: string,
    adTextType: AdTextType
  ) => Promise<AxiosResponse<void> | undefined>
  apiGenerateBlog: (
    productId: string,
    blogStepper: BlogStepper,
    subSteps?: [number, number][]
  ) => Promise<AxiosResponse<void> | undefined>
  apiGenerateContextualBlog: (
    productId: string,
    context: WriteMoreGenerationContext
  ) => Promise<AxiosResponse<Description[]> | undefined>
  apiGenerateProductDescription: (
    productId: string
  ) => Promise<AxiosResponse<void> | undefined>
  apiCancelDescribeProduct: (productId: string) => Promise<AxiosResponse<void>>

  // Credit-related methods
  showInsufficientCreditsModal: boolean
  setShowInsufficientCreditsModal: (show: boolean) => void
}

/**
 * Props that are common to the generation API hook and provider
 */
export interface GenerationAPIHookAndProviderProps {}

/**
 * Props that are exclusive to the generation API context provider
 */
export interface GenerationAPIProviderProps
  extends GenerationAPIHookAndProviderProps {
  /**
   * Callback when a rate-limit is exceeded
   */
  onRateLimitExceeded: () => void
}

/**
 * Props that are exclusive to the generation API hook
 */
export interface GenerationAPIHookProps
  extends GenerationAPIHookAndProviderProps {}

// ===== Context & Provider =====
/**
 * Generation API context
 */
export const GenerationAPIContext = React.createContext<
  IGenerationAPIContext | undefined
>(undefined)

/**
 * Generation API Context Provider
 */
export const GenerationAPIProvider: React.FC<GenerationAPIProviderProps> = (
  props
) => {
  const { children, onRateLimitExceeded } = props

  // Controls display of CTA modal when user runs out of credits
  // TODO: Move this out so this provider can deal purely with the REST API
  const [
    showInsufficientCreditsModal,
    setShowInsufficientCreditsModal
  ] = useState<boolean>(false)

  /**
   * Handle known errors from generation API endpoints
   */
  const handleKnownGenerationErrors = async (error: unknown) => {
    // Check if it's an Axios error first
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 429) {
        // If a rate-limit error is exceeded, this callback should notify the user
        await onRateLimitExceeded()
      } else if (
        error.response?.data.detail?.error === ServerErrorType.outOfCredits
      ) {
        // If not enough credits, show the modal
        setShowInsufficientCreditsModal(true)
      } else {
        // Not a known Axios error; rethrow
        throw error
      }
    } else {
      // Not an Axios error; rethrow
      throw error
    }
    // Return undefined to keep TypeScript happy
    return undefined
  }

  /**
   * Rewrite a given piece of text
   */
  const apiGenerateRewrittenText = async (text: string) => {
    const response = await apiRewriteText({
      data: { original_content: text }
    }).catch(handleKnownGenerationErrors)
    return response?.data
  }

  /**
   * Generate an advertisement
   * @param productId
   * @param {AdTextType} adTextType The type of ad to generate (instagram, google, etc)
   */
  const apiGenerateAdvertisingText = async (
    productId: string,
    adTextType: AdTextType
  ) => {
    // Compute generationType based on adTextType (this is a shim until we directly accept generationType as a param)
    const generationType: ContentGenerationType = generationTypeMap[adTextType]

    return await apiGenerateContent({
      params: {
        productId,
        generation_type: generationType
      }
    }).catch(handleKnownGenerationErrors)
  }

  /**
   * Generate content for (one step of) a blog
   * @param {string} productId
   * @param {BlogStepper} blogStepper Which step of the blog to generate for
   * @param {[number, number][]} subSteps Only for blogStepper.FULL_ARTICLE; payload for which parts of article to generate
   */
  const apiGenerateBlog = async (
    productId: string,
    blogStepper: BlogStepper,
    subSteps?: [number, number][]
  ) => {
    // Compute generationType based on blogStepper (this is a shim until we directly accept generationType as a param)
    const generationTypeMap: { [key in BlogStepper]: ContentGenerationType } = {
      [BlogStepper.TITLE]: ContentGenerationType.blog_title,
      [BlogStepper.OUTLINE]: ContentGenerationType.blog_outline,
      [BlogStepper.INTRO]: ContentGenerationType.blog_introduction,
      // Note this duplicate is intentional
      [BlogStepper.FULL_ARTICLE]: ContentGenerationType.blog_paragraph,
      [BlogStepper.PARAGRAPH]: ContentGenerationType.blog_paragraph,
      [BlogStepper.CONCLUSION]: ContentGenerationType.blog_conclusion
    }
    const generationType: ContentGenerationType = generationTypeMap[blogStepper]

    let payload: [number, number][] = [[blogStepper, -1]] // [[step, subStep], ...]

    // For full article, specify whether to generate intro, any number of paras, and conclusion
    if (
      blogStepper === BlogStepper.FULL_ARTICLE ||
      blogStepper === BlogStepper.PARAGRAPH
    ) {
      if (subSteps === undefined) {
        throw new Error('subSteps must be specified when generating paragraphs')
      }
      payload = subSteps
    }

    return await apiGenerateContent({
      data: payload,
      params: {
        productId,
        generation_type: generationType
      }
    }).catch(handleKnownGenerationErrors)
  }

  /**
   * Request the generation API to generate descriptions for a product
   * @param {string} productId
   */
  const apiGenerateProductDescription = async (productId: string) => {
    return await apiGenerateContent({
      params: {
        productId,
        generation_type: ContentGenerationType.product_descriptions
      }
    }).catch(handleKnownGenerationErrors)
  }

  /**
   * Cancel content generation
   * @param {string} productId
   */
  const apiCancelGenerateContent = async (productId: string) => {
    const config = {
      params: {
        productId: encodeURIComponent(productId)
      }
    }
    const data = {}
    return apiClient.post<void>(
      '/describe/cancel/',
      data, // Can be just 'null'
      config
    )
  }

  /**
   * Request the contextual generation API to generate content and return it
   * immediately as a Description object.
   *
   * @param productId Product ID
   * @param context   Context required for generation. e.g. prefix, -> ..., suffix -> ...
   * @returns         Trivial list of Description objects with generated content
   */
  const apiGenerateContextualBlog = async (
    productId: string,
    context: WriteMoreGenerationContext
  ) => {
    // Compute generationType
    const generationType: ContentGenerationType =
      ContentGenerationType.blog_write_more

    return await apiWriteMore({
      data: context,
      params: {
        productId: productId,
        generation_type: generationType
      }
    }).catch(handleKnownGenerationErrors)
  }

  return (
    <GenerationAPIContext.Provider
      value={{
        rewriteText: apiGenerateRewrittenText,
        apiGenerateAdvertisingText,
        apiGenerateBlog,
        apiGenerateContextualBlog,
        apiGenerateProductDescription,
        apiCancelDescribeProduct: apiCancelGenerateContent,
        showInsufficientCreditsModal,
        setShowInsufficientCreditsModal
      }}
    >
      {children}
    </GenerationAPIContext.Provider>
  )
}

// ===== Hook & HOC =====
/**
 * Hook for requesting various content types from the Generation API.
 */
export const useGenerationAPI = (_props?: GenerationAPIHookProps) => {
  // NOTE: props currently unused, but can be used to override the provider's defaults in the future
  const context = useContext(GenerationAPIContext)
  if (context === undefined) {
    throw new Error(
      'useGenerationAPI must be used within a GenerationAPIProvider'
    )
  }
  return context
}

export const withGenerationAPI = (props?: GenerationAPIHookProps) => {
  return <Props extends IGenerationAPIContext>(
    Component: React.ComponentType<Props>
  ) => {
    const Wrapper: React.FC<Subtract<Props, IGenerationAPIContext>> = (
      componentProps
    ) => {
      const generationAPIHookResult = useGenerationAPI(props)
      return (
        <Component
          {...generationAPIHookResult}
          {...(componentProps as Props)}
        />
      )
    }
    return Wrapper
  }
}
