// ===== CONSTANTS =====
import { KeywordBarStyle, KeywordStrength } from './Interfaces'
import { Tour, TourType } from '@hypotenuse/common/src/interfaces/Tutorial'

export const PRODUCT_TAGS_MAX_CHARS = 500

export const STAGE = process.env.REACT_APP_STAGE
export const IS_PRODUCTION = STAGE === 'prod'
export const IS_DEPLOYED = STAGE === 'staging' || IS_PRODUCTION
export const SERVER = process.env.REACT_APP_SERVER || ''
export const SHOPIFY_ADD_PRODUCT_URL = `${SERVER}/shopify/add_product_page`
export const SUPPORT_EMAIL = 'support@hellotako.com'
export const SUPPORT_SUBJECT = 'Help%20with%20Tako'
export const SUPPORT_BODY =
  'Hello%20Tako%2C%0D%0A%0D%0AI%20am%20reaching%20out%20to%20you%20for%20help%20with%20%5Bfeature%2Fproblem%5D%0D%0A%0D%0AThank%20you!%0D%0A%0D%0ARegards%2C%0D%0A%5Byour%20name%5D%0D%0A'

export const LOGO_SHORT_SRC = '/logos/logo_short_outline_purple.svg'
export const LOGO_LONG_SRC = '/logos/logo_long_outline_purple.svg'

// ===== SHOPIFY AUTH =====
// Referenced from backend/app/constants.py
export const SHOPIFY_API_KEY = IS_PRODUCTION
  ? '5fb2fc0bcb17a97c16d58dab9535b586'
  : 'fdff19ba90235c4c894e03a60f7216e0'
export const SHOPIFY_APP_HOST = process.env.REACT_APP_APP_HOST
export const APP_LAUNCHED_REDIRECT_URL = `${SHOPIFY_APP_HOST}/shopify/app_launched`
// https://shopify.dev/docs/admin-api/access-scopes
export const SHOPIFY_ACCESS_SCOPES = [
  'read_products',
  'write_products',
  'read_script_tags',
  'read_themes',
  'read_checkouts'
]
// ===== Dialog ID =====
export const GENERATE_WITHOUT_TAGS_DIALOG_ID =
  'generate-description-without-tags'

// ===== UNDO DELAY TIMING =====
export const DEFAULT_DELAY_TIMING = 1500

// ===== HELP SCOUT BEACON =====
export const HELPSCOUT_BEACON_ID = 'ab86064c-229e-46da-915f-fd877c3c979c'
export const DISABLE_BEACON_LINKS = false

// ===== CHAT SUPPORT =====
const PAPERCUPS_PRIMARY_COLOR = getComputedStyle(
  document.documentElement
).getPropertyValue('--themeCol1-primary-color')

export const PAPERCUPS_ACCOUNT_ID = 'cf2ea147-920e-49a2-9751-60d832aebe29'
export const PAPERCUPS_CHAT_TITLE = 'Welcome to Tako'
export const PAPERCUPS_CHAT_SUBTITLE =
  'Ask us anything in the chat window below 😊'
export const PAPERCUPS_CHAT_PLACEHOLDER = 'Start typing...'
export const PAPERCUPS_CHAT_GREETING = 'Hi there! How can I help you?'
export const PAPERCUPS_CHAT_COLOR = PAPERCUPS_PRIMARY_COLOR
export const PAPERCUPS_BASE_URL = 'https://app.papercups.io'

// ===== FULL STORY =====
export const FULLSTORY_ORGID = 'ZW9GE'

// ===== SENTRY =====
export const SENTRY_DSN =
  'https://27eea719755e42f1a96cd982cd39bec9@o489922.ingest.sentry.io/5554954'
/**
 * Trace sample rate for Sentry. This is a number between 0 and 1, where 1 means
 * all traces will be sent to Sentry.
 */
export const SENTRY_TRACE_SAMPLE_RATE = 0.01

// ===== GOOGLE ANALYTICS =====
export const GOOGLE_ANALYTICS_TRACK_CODE = 'UA-159925479-3'

// ===== LOGROCKET =====
export const LOGROCKET_APP_ID_1 = 'd5hj4i/hellotako-prod-ansz9'
export const LOGROCKET_APP_ID_2 = 'bippsm/hellotako-prod'
export const LOGROCKET_APP_ID = LOGROCKET_APP_ID_2 // To be switched out when running close to LogRocket limits

// ===== CUSTOMER IO =====
export const CIO_APP_ID = 100951

// ===== INTERVIEW =====
export const CALENDLY_URL = 'https://calendly.com/joshuawong/tako-intro'

export const SHOPIFY_HELPHERO_TUTORIAL_TOUR: Tour = {
  id: 'q9xtMHuxFVt',
  name: 'tutorial_tour',
  type: TourType.helphero
}

export const MIN_NUM_STRONG_KEYWORDS = 4

export const KEYWORD_BAR_COLOR: KeywordBarStyle = {
  [KeywordStrength.weak]: '#EE1A31',
  [KeywordStrength.average]: '#FFCC47',
  [KeywordStrength.strong]: '#65CB75'
}

// ===== GOOGLE TAG MANAGER CONSTANTS =====
// Container ID, should be like 'GTX-XXXXXXX'
const GTM_LIVE_CONTAINER = 'GTM-KB9793D'
const GTM_DEV_CONTAINER = 'GTM-N9MWNPS'
export const GTM_CONTAINER_ID = IS_DEPLOYED
  ? GTM_LIVE_CONTAINER
  : GTM_DEV_CONTAINER

// ====== Feature Flags ======
/**
 * Share blog article previews (for public viewing) using permalinks.
 * Disabled on Tako since it's not ported over to Tako.
 */
export const TAKO_ENABLE_ARTICLE_PUBLIC_SHARING = true
export const TAKO_ENABLE_NEW_REFERRALS = true
