import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { BLOG_PREVIEW_BASE_PATH } from '../../utils/Constants'

/**
 * @deprecated This component only serves as a redirect. The main page has
 * been moved to the Next.js app in phoenix-next (at `/article`)
 */
const ArticlePreviewRedirect = () => {
  const [articlePreviewId] = useQueryParam('id', StringParam)
  const redirectUrl = articlePreviewId
    ? `${BLOG_PREVIEW_BASE_PATH}/${articlePreviewId}`
    : '/home'
  useEffect(() => {
    window.location.href = redirectUrl
  }, [redirectUrl])
  return null
}

export default ArticlePreviewRedirect
