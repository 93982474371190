import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
// import { store } from './app/store'
// import { Provider } from 'react-redux'

import './index.css'
import '@hypotenuse/common/src/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {
  IS_DEPLOYED,
  SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
  STAGE
} from './utils/Constants'
import Analytics from './analytics/Analytics'
import { ApiErrorHandler } from './components/ApiErrorHandler'

// Sentry logging for error debugging
if (IS_DEPLOYED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    environment: STAGE,
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE
  })
}

const history = createBrowserHistory()
history.listen((_location) => {
  void Analytics.trackPageView(
    window.location.pathname + window.location.search
  )
})

ReactDOM.render(
  <React.StrictMode>
    {/*<Provider store={store}>*/}
    <Router history={history}>
      <ApiErrorHandler>
        <App />
      </ApiErrorHandler>
    </Router>
    {/*</Provider>*/}
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
