import React from 'react'
import { useGenerationAPI } from '@hypotenuse/common/src/hooks/useGenerationAPI'
import {
  InsufficientCreditsModal,
  InsufficientCreditsModalProps
} from '@hypotenuse/common/src/components/InsufficientCreditsModal'

export interface TakoInsufficientCreditsModalProps
  extends Partial<InsufficientCreditsModalProps> {}

/**
 * Wrapper around the generic InsufficientCreditsModal to customize it for Tako.
 *
 * Also serves as a workaround for the fact that useGenerationAPI() is not currently usable
 * directly inside the `App` component due to its Provider being further down in the tree.
 */
export const TakoInsufficientCreditsModal: React.FC<TakoInsufficientCreditsModalProps> = (
  props
) => {
  const {
    showInsufficientCreditsModal,
    setShowInsufficientCreditsModal
  } = useGenerationAPI()
  return (
    <InsufficientCreditsModal
      open={showInsufficientCreditsModal}
      onClose={() => setShowInsufficientCreditsModal(false)}
      topDivider={false}
      modalMessage={`You won't be able to generate any more content. Don't worry, your current content is still safe.
      \n\nTo continue creating more content, upgrade your plan now.`}
      redirectTo="/pricing"
      primaryText="Purchase Credits"
      {...props}
    />
  )
}
