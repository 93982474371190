import { Description, ProductErrorType } from './Products'

export enum BlogStepper {
  TITLE,
  OUTLINE,
  INTRO,
  PARAGRAPH,
  CONCLUSION,
  FULL_ARTICLE
}

export enum BlogCurrStep {
  NONE = -1,
  TITLE = 0,
  OUTLINES = 1,
  PARAGRAPHS = 2
}

/**
 * The pattern used to split outline headings into an array.
 * Note: We include <SEP> for backward compatibility - we don't expect to see
 *  it in newly generated outlines but older outlines still contain it.
 */
export const BLOG_OUTLINE_SEPARATOR_PATTERN = /\n|<SEP>/
/**
 * The patter used to rejoin outline headings arrays into a string
 */
export const BLOG_OUTLINE_JOIN_TOKEN = '\n'

export enum BlogTheme {
  WRITER_BLOG_TITLE = 'blog_title',
  WRITER_BLOG_OUTLINE = 'blog_outline',
  WRITER_BLOG_INTRO = 'blog_introduction',
  WRITER_BLOG_PARAGRAPH = 'blog_paragraph',
  WRITER_BLOG_CONCLUSION = 'blog_conclusion',
  WRITER_BLOG_WRITE_MORE = 'blog_write_more' // TODO: consider different writers based on prefix or suffix
}

export enum BlogPOVs {
  FIRST_PERSON_SINGULAR = 'first-person (singular)',
  FIRST_PERSON_PLURAL = 'first-person (plural)',
  SECOND_PERSON = 'second-person',
  THIRD_PERSON = 'third-person'
}

export enum OutlineDetailCreationType {
  BY_USER = 'by_user',
  BY_RESEARCH = 'by_research'
}
export interface OutlineDetail {
  outline_detail: string
  creation_type: OutlineDetailCreationType
  id: string
}

/**
 * The outline section is a representation of H2s + Ps
 */
export interface OutlineSection {
  /** This string stores the one heading (H2) */
  outline_heading: string
  /** This array of string stores the bullet points of each heading (H2) */
  details: OutlineDetail[]
  /** This id is used to identify the outline section */
  id: string
}

export interface OutlineWithDetails {
  /**
   * This list stores all the headings of an outline
   * and the details (bullet points) of each heading
   */
  list_of_outline_sections: OutlineSection[]
  /**
   *  This id is used to keep track of which outline is currently selected.
   * In Step 3 of Blog, we will render the
   * outline based on the selected_outline_id
   */
  outline_id: string
}

// All Descriptions inside Blog object will have a description_id
export interface BlogDescription extends Description {
  description_id: string
}

export interface Blog {
  username: string
  organizationName: string
  blogId: string
  product_details: string
  productId: string
  created_at: string
  loading: boolean
  descriptions: BlogDescription[]
  step: number
  target_audience: string
  brand_details: string
  title: string
  intro: string
  blog_outlines: string[]
  blog_outlines_details: string[][]
  paragraphs: string[]
  blog_content: string
  keywords: string[]
  conclusion: string
  loadingAt?: Date
  expiredAt?: Date
  productErrorType?: ProductErrorType
  blog_topic?: string
  blog_tone: string
  point_of_view: BlogPOVs
  outline_options: OutlineWithDetails[]
  selected_outline_id: string
}

export interface BlogUpdateForm {
  blogId: string
  blog_topic?: string
  product_details?: string
  target_audience?: string
  brand_details?: string
  title?: string
  intro?: string
  blog_outlines?: string[]
  paragraphs?: string[]
  blog_content?: string
  descriptions?: BlogDescription[]
  keywords?: string[]
  conclusion?: string
  step?: BlogCurrStep
  productErrorType?: ProductErrorType
  blog_tone?: string
  point_of_view?: BlogPOVs
  outline_options?: OutlineWithDetails[]
  selected_outline_id?: string
}

export type SortByDate =
  | BlogOverviewSortBy.CREATED_AT
  | BlogOverviewSortBy.MODIFIED_AT

export enum BlogOverviewSortBy {
  SCORE = '_score',
  BLOG_ID = 'blogId',
  CREATED_AT = 'created_at',
  MODIFIED_AT = 'modified_at',
  BLOG_TITLE = 'title.raw'
}

export interface BlogOverviewSearchQuery {
  blogIds?: Array<string>
  search?: string
  scan?: boolean
  sortBy?: Array<BlogOverviewSortBy>
  start?: number
  end?: number
}

export interface BlogOverviewResponse {
  created_at: string
  blogId: string
  title: string
  keywords: Array<string>
  modified_at?: string
  blog_topic: string
  word_count: number
}

export enum BlogWriteMoreAction {
  APPROVE = 'approve',
  REJECT = 'reject'
}

/**
 * Schema for frozen blog article preview.
 * A blog article preview is a frozen version of a blog article that is
 * suitable for sharing.
 */
export interface IArticlePreview {
  id: string
  created_at: string
  blog_id: string
  title: string
  html_content: string
  author_display_name: string
}
