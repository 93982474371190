import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Image } from 'semantic-ui-react'
import {
  APP_LAUNCHED_REDIRECT_URL,
  SHOPIFY_ACCESS_SCOPES,
  SHOPIFY_API_KEY
} from '../utils/Constants'

function UnauthorizedError() {
  const [authUrl, setAuthUrl] = useState<string>()

  useEffect(() => {
    setTimeout(async () => {
      // Form shopify redirect url to redirect user to shopify login
      let url = 'https://www.shopify.com/admin/oauth/authorize?'
      url += [
        `client_id=${SHOPIFY_API_KEY}`,
        `scope=${SHOPIFY_ACCESS_SCOPES.join()}`,
        `redirect_uri=${APP_LAUNCHED_REDIRECT_URL}`
      ].join('&')

      setAuthUrl(url)
    }, 2000)
  }, [])

  useEffect(() => {
    if (authUrl) {
      window.location.href = authUrl
    }
  }, [authUrl])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      data-testid="unauthorizedPage"
    >
      <Image src="/assets/authentication_needed.svg" size="large" />
      <h2 style={{ textAlign: 'center', padding: '10px' }}>
        Sit tight! We are redirecting you back to Shopify for authentication.
      </h2>
    </Box>
  )
}

export default UnauthorizedError
