import React from 'react'
import { Subtract } from 'utility-types'

/**
 * Type definition for a hook function that takes a single argument and returns a result
 */
type HookWithoutParams<R> = () => R

/**
 * Type definition for a HOC that injects props into a component in such a way that
 * the injected props don't need to be supplied when the wrapped component is instantiated.
 */
type PropInjectorHOC<T extends object> = <InjectedProps extends T>(
  Component: React.ComponentType<InjectedProps>
) => React.FunctionComponent<Subtract<InjectedProps, T>>

/**
 * A function that transforms a hook function (which takes no arguments) into a prop-injection style HOC.
 *  * References:
 * {@link https://github.com/ricokahler/hocify/blob/29e166205f81aae8e262604d598a6adee57df952/index.js}
 * {@link https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb}
 * {@link https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/}
 *
 * TODO: Extend to allow hooks that take parameters.
 * TODO: Extend to allow the 'enhancer' pattern.
 */
export const makePropInjectorHOCFromHook = <HookResult extends object>(
  hook: HookWithoutParams<HookResult>
): PropInjectorHOC<HookResult> => {
  return <Props extends HookResult>(Component: React.ComponentType<Props>) => {
    const WrapperComponent: React.FunctionComponent<
      Subtract<Props, HookResult>
    > = (props) => {
      const hookResult = hook()
      return <Component {...hookResult} {...(props as Props)} />
    }
    WrapperComponent.displayName = `withInjectedProps(${
      Component.displayName || Component.name
    })`
    return WrapperComponent
  }
}
