import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface ProtectedRouteProps extends RouteProps {
  hasAccess?: boolean
}

export interface ProtectedRouteWithRedirectProps extends ProtectedRouteProps {
  redirectUrl: string
}

export const ProtectedRouteWithRedirect: React.FC<ProtectedRouteWithRedirectProps> = (
  props
) => {
  const hasAccess = typeof props.hasAccess === 'undefined' || props.hasAccess
  if (hasAccess) {
    return <Route {...props} />
  } else {
    console.log(
      `User does not have access to ${props.path}. Redirecting to ${props.redirectUrl}`
    )
    return <Redirect to={props.redirectUrl} />
  }
}

export interface ProtectedRouteWithFallbackComponentProps
  extends ProtectedRouteProps {
  fallbackComponent: React.ReactNode
}

export const ProtectedRouteWithFallbackComponent: React.FC<ProtectedRouteWithFallbackComponentProps> = (
  props
) => {
  const { hasAccess, fallbackComponent } = props
  if (hasAccess) {
    return <Route {...props} />
  } else {
    return <>{fallbackComponent}</>
  }
}
