import React, { useRef, useState } from 'react'
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import GeneralModal from '@hypotenuse/common/src/components/GeneralModal'

interface CommonProps {
  title: string
  content?: React.ReactNode
  disableDontShowAgain?: boolean
  cancelText?: string
  confirmText?: string
  onCancel?: () => void
  onConfirm?: () => void
}

export interface AlertDialogProps extends CommonProps {
  isOpen: boolean
  dontShowAgain?: boolean
  setDontShowAgain?: (value: boolean) => void
  confirmText: string
  cancelText: string
  onCancel: () => void
  onConfirm: () => void
}

const AlertDialog: React.FunctionComponent<AlertDialogProps> = (props) => {
  const {
    isOpen,
    title,
    content,
    cancelText,
    confirmText,
    dontShowAgain,
    setDontShowAgain,
    disableDontShowAgain,
    onCancel,
    onConfirm
  } = props
  return (
    <GeneralModal
      isOpen={isOpen}
      header={title}
      body={
        <Grid container spacing={2} direction="column">
          {content &&
            (typeof content === 'string' ? (
              <Grid item>
                <Typography>{content}</Typography>
              </Grid>
            ) : (
              content
            ))}
          {!disableDontShowAgain && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontShowAgain}
                    onChange={(_, checked) => {
                      setDontShowAgain?.(checked)
                    }}
                  />
                }
                label="Don't show again"
              />
            </Grid>
          )}
        </Grid>
      }
      primaryText={confirmText}
      primaryFunc={onConfirm}
      primaryColor={'primary'}
      secondaryText={cancelText}
      secondaryFunc={onCancel}
      onClose={onCancel}
      size="mini"
    />
  )
}

export interface UseConfirmationOptions extends CommonProps {
  disabled?: boolean
  onShow?: () => void
}

export interface UserConfirmationResponse {
  isConfirmed: boolean
  dontShowAgain?: boolean
}

export interface IUseConfirmationDialog {
  /**
   * Function that triggers the confirmation dialog
   */
  getUserConfirmation: () => Promise<UserConfirmationResponse>
  /**
   * The alert dialog component - add this to your component tree.
   */
  alertDialog: React.ReactNode
  /**
   * The alert dialog component - add this to your component tree and pass it `alertDialogProps`
   *
   * @deprecated Use {@property alertDialog} directly
   */
  AlertDialog: React.FC<AlertDialogProps>
  /**
   *
   * @deprecated Use {@property alertDialog} directly
   */
  alertDialogProps: AlertDialogProps
}

export const useConfirmationDialog = (
  options: UseConfirmationOptions
): IUseConfirmationDialog => {
  const {
    title,
    content,
    disabled,
    disableDontShowAgain,
    onShow,
    onConfirm,
    onCancel,
    confirmText = 'Confirm',
    cancelText = 'Cancel'
  } = options

  const [isOpen, setIsOpen] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false)
  const openModal = () => {
    setIsOpen(true)
    onShow?.()
  }
  const closeModal = () => {
    setIsOpen(false)
    setDontShowAgain(false)
  }

  const resolveCallback = useRef<
    ((value: UserConfirmationResponse) => void) | null
  >(null)
  const getUserConfirmation = async (): Promise<UserConfirmationResponse> => {
    if (disabled) return { isConfirmed: true }
    openModal()
    return new Promise<UserConfirmationResponse>((resolve) => {
      resolveCallback.current = resolve
    })
  }

  const confirm = () => {
    closeModal()
    resolveCallback.current?.({
      isConfirmed: true,
      dontShowAgain: dontShowAgain || undefined
    })
    onConfirm?.()
  }
  const cancel = () => {
    closeModal()
    resolveCallback.current?.({ isConfirmed: false })
    onCancel?.()
  }

  const alertDialogProps: AlertDialogProps = {
    isOpen,
    title,
    content,
    dontShowAgain,
    setDontShowAgain,
    disableDontShowAgain,
    cancelText,
    confirmText,
    onConfirm: confirm,
    onCancel: cancel
  }

  const alertDialog = <AlertDialog {...alertDialogProps} />

  return {
    getUserConfirmation,
    alertDialog,
    AlertDialog,
    alertDialogProps
  }
}
