/**
 * Utility function to enable using Notistack's `useSnackbar` outside React components.
 * Reference: https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
 *
 * The core of this workaround: We create a placeholder for the result of `useSnackbar()`.
 * When mounted as a child of a `SnackbarProvider`, the `SnackbarUtilsConfigurator` sets `useSnackbarRef`, giving us
 * access to all of Notistack's functionality without explicitly calling the `useSnackbar` hook
 */
import {
  useSnackbar,
  WithSnackbarProps,
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  VariantType
} from 'notistack'
import { Button } from '@material-ui/core'
import React from 'react'
import styled from '@emotion/styled'

let openChat: () => void
interface InnerConfiguratorProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
  openChat: () => void
}

const InnerSnackbarUtilsConfigurator: React.FC<InnerConfiguratorProps> = (
  props: InnerConfiguratorProps
) => {
  props.setUseSnackbarRef(useSnackbar())
  openChat = props.openChat
  return null
}

let useSnackbarRef: WithSnackbarProps
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp
}

interface SnackbarConfiguratorProps {
  openChat: () => void
}

export const SnackbarConfigurator = (props: SnackbarConfiguratorProps) => (
  <InnerSnackbarUtilsConfigurator
    setUseSnackbarRef={setUseSnackbarRef}
    openChat={props.openChat}
  />
)

// region Per-variant options
export type VariantOptions = {
  [key in VariantType]?: OptionsObject
}

/**
 * Defines default options for each snackbar variant, overriding the global default snackbar options
 * @type {{error: {action: (key) => JSX.Element, autoHideDuration: null}}}
 */
const defaultVariantOptions: VariantOptions = {
  error: {
    autoHideDuration: 30000,
    action: (key) => (
      <>
        <Button
          color="inherit"
          size="small"
          onClick={() => useSnackbarRef.closeSnackbar(key)}
          style={{ fontWeight: 'bold' }}
        >
          Dismiss
        </Button>
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            openChat()
            useSnackbarRef.closeSnackbar(key)
          }}
          style={{ fontWeight: 'bold' }}
        >
          Get Help
        </Button>
      </>
    )
  }
}
// endregion

// Wrapper around snackbar content to prevent overflow by truncating using ellipses
const SnackbarContentEllipsisDiv = styled.div`
  // Limit width of snackbar content to prevent horizontal overflow.
  max-width: 90vw;
  max-height: 30vh;

  // Vertical scroll
  overflow-y: auto;

  font-size: 16px;

  // Prevent horizontal scrolling
  overflow-x: hidden;

  // Not sure if this is necessary
  text-overflow: ellipsis;
`

export const snackbar = {
  show(msg: SnackbarMessage, options?: OptionsObject): SnackbarKey {
    const variant = options?.variant
    const variantOptions = defaultVariantOptions[variant ?? 'default'] ?? {}
    const mergedOptions = { ...variantOptions, ...options }
    return useSnackbarRef.enqueueSnackbar(
      <SnackbarContentEllipsisDiv>{msg}</SnackbarContentEllipsisDiv>,
      mergedOptions
    )
  },
  close(key?: SnackbarKey) {
    useSnackbarRef.closeSnackbar(key)
  }
}

export default snackbar
