/**
 * Cost info for generating content for a single product
 */
export interface ContentGenerationCreditCostInfo {
  product_id: string
  cost: number
  is_free_regenerate: boolean
}

/**
 * Cost info for generating content for a batch of products
 */
export interface BatchContentGenerationCreditCostInfo {
  total_cost: number
  product_id_cost_info_map: { string: ContentGenerationCreditCostInfo }
}

/**
 * 'Legacy' interface (still technically in use on the backend) for cost info
 * for generating content for products.
 *
 * @deprecated Use {@link BatchContentGenerationCreditCostInfo} or
 * {@link ContentGenerationCreditCostInfo} instead
 */
export interface ProductDescribeCostInResponse {
  describe_cost: number
  is_free_regenerate?: boolean
  product_ids: string[]
  error_message?: string
}

/**
 * Granular content generation subtypes
 */
export enum ContentGenerationType {
  // Product descriptions
  product_descriptions = 'product_descriptions',

  // Blog
  blog_title = 'blog_title',
  blog_outline = 'blog_outline',
  blog_introduction = 'blog_introduction',
  blog_paragraph = 'blog_paragraph',
  blog_conclusion = 'blog_conclusion',
  blog_write_more = 'blog_write_more',

  // Ads
  advertising_text_instagram = 'advertising_text_instagram',
  advertising_text_google = 'advertising_text_google',
  advertising_text_facebook = 'advertising_text_facebook',
  advertising_text_headline = 'advertising_text_headline',
  advertising_text_linkedin = 'advertising_text_linkedin',
  advertising_text_rewriter = 'advertising_text_rewriter',
  advertising_text_category_page = 'advertising_text_category_page',
  advertising_text_landing_page = 'advertising_text_landing_page',
  advertising_text_eli5 = 'advertising_text_eli5',
  advertising_text_email = 'advertising_text_email',
  advertising_text_video_intro = 'advertising_text_video_intro',
  advertising_text_meta_desc = 'advertising_text_meta_desc',
  advertising_text_marketing_angles = 'advertising_text_marketing_angles',
  advertising_text_press_release = 'advertising_text_press_release',
  advertising_text_summarise = 'advertising_text_summarise',
  advertising_text_youtube_title = 'advertising_text_youtube_title',
  advertising_text_bio_writer = 'advertising_text_bio_writer',

  // Image generation
  text_to_image_generation = 'text_to_image_generation'
}

export enum FeedbackContentGenerationType {
  // Blog
  blog_write_more = 'blog_write_more',

  // Image generation
  text_to_image_generation = 'text_to_image_generation',
  text_to_image_generation_batch = 'text_to_image_generation_batch',

  // Ads
  advertising_text_instagram = 'advertising_text_instagram',
  advertising_text_google = 'advertising_text_google',
  advertising_text_facebook = 'advertising_text_facebook',
  advertising_text_headline = 'advertising_text_headline',
  advertising_text_linkedin = 'advertising_text_linkedin',
  advertising_text_rewriter = 'advertising_text_rewriter',
  advertising_text_category_page = 'advertising_text_category_page',
  advertising_text_landing_page = 'advertising_text_landing_page',
  advertising_text_eli5 = 'advertising_text_eli5',
  advertising_text_email = 'advertising_text_email',
  advertising_text_video_intro = 'advertising_text_video_intro',
  advertising_text_meta_desc = 'advertising_text_meta_desc',
  advertising_text_marketing_angles = 'advertising_text_marketing_angles',
  advertising_text_press_release = 'advertising_text_press_release',
  advertising_text_summarise = 'advertising_text_summarise',
  advertising_text_youtube_title = 'advertising_text_youtube_title',
  advertising_text_bio_writer = 'advertising_text_bio_writer'
}

/**
 * Top-level content generation types
 */
export enum ContentGenerationCategory {
  product_descriptions = 'product_descriptions',
  blog = 'blog',
  advertising = 'advertising'
}

/**
 * Generation status filter key
 */
export enum GenerationStatusFilterKey {
  all = 'all',
  generated = 'generated',
  not_generated = 'not_generated',
  generation_error = 'generation_error',
  marked_as_done = 'marked_as_done'
}
