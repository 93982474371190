import React from 'react'
import { Link } from '@material-ui/core'
import { useBeacon } from '../useBeacon'
import { ArticleOpenMode } from '../types'

export interface Props {
  articleId: string
  openMode?: ArticleOpenMode
  style?: React.CSSProperties
}

const BeaconArticleLink: React.FC<Props> = (props) => {
  const { children, articleId, openMode } = props

  const { beacon, isBeaconLoaded, disableBeaconLinks } = useBeacon()

  const handleClick = () => {
    // Open the Help Scout article
    beacon?.('article', articleId, {
      type: openMode
    })
    // TODO: Track clicks on link.
    //  Currently unable to track because Analytics is defined separately in
    //  /platform and /shopify, and cannot be imported into /common.
  }

  if (disableBeaconLinks) return null

  return (
    // Don't display anything until beacon is fully loaded
    <>
      {isBeaconLoaded && (
        <Link
          style={{ ...props.style, verticalAlign: 'inherit' }}
          onClick={handleClick}
          component="button"
          type="button"
        >
          {children}
        </Link>
      )}
    </>
  )
}

BeaconArticleLink.defaultProps = {
  openMode: 'sidebar'
}

export default BeaconArticleLink
