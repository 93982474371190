// More configs: https://material-ui.com/customization/palette/
import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#566bce'
    },
    secondary: {
      main: '#279c9a'
    },
    error: {
      main: '#ee1a31' // Red
    }
  },
  typography: {
    h1: {
      fontSize: '4rem',
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      color: 'var(--themeCol1-font-color)'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 550
    },
    h6: {
      fontSize: '1.1rem',
      '@media (min-width:600px)': {
        fontSize: '1.15rem'
      },
      fontWeight: 550
    },
    caption: {
      fontSize: '14px',
      lineHeight: 1.1
    }
  },
  overrides: {
    // MUI's buttons look really bad, esp since they are uppercase
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        color: 'var(--themeCol2-font-color)'
      },
      outlined: {
        backgroundColor: 'white',
        boxShadow: '1px 2px #F0F0F0',
        '&:hover': {
          backgroundColor: '#EFEFEF'
        }
      }
    }
  }
})
