import React from 'react'
import GeneralError from './GeneralError'

function UnexpectedError() {
  return (
    <GeneralError
      textHeader="We're fixing it"
      textBody="This page is experiencing hiccups. Our engineers know about this problem and we’re working to get this back to normal quickly."
      textCta="Go back home"
      handleCta={() => {
        window.location.href = '/'
      }}
    />
  )
}

export default UnexpectedError
