import React from 'react'
import { useIsMobile } from '../../hooks/UseIsMobile'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import MobileWarningBannerProps from './MobileWarningBannerProps'

/**
 * Hook that handles logic & provides props for the mobile warning banner
 */
const useMobileWarningBanner: () => MobileWarningBannerProps = () => {
  // Only show the banner on mobile devices
  const isMobile = useIsMobile()
  // If the user has dismissed the banner, don't show it again
  const [dismissed, setDismissed] = useLocalStorage(
    'mobile-warning-banner-dismissed',
    false
  )
  return { hidden: !isMobile || dismissed, onClose: () => setDismissed(true) }
}

export default useMobileWarningBanner

export const withMobileWarningBanner = <
  Props extends { mobileWarningBannerProps: MobileWarningBannerProps }
>(
  Component: React.ComponentType<Props>
) => {
  const Wrapper: React.FC<Omit<Props, 'mobileWarningBannerProps'>> = (
    componentProps
  ) => {
    const hookResult = useMobileWarningBanner()
    return <Component {...hookResult} {...(componentProps as Props)} />
  }
  return Wrapper
}
