import React, { useEffect, useState } from 'react'
import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import UnauthorizedError from './UnauthorizedError'
import { ApiError } from '../utils/Interfaces'
import { AxiosError } from 'axios'

interface Props {
  children: JSX.Element
}

export function ApiErrorHandler(props: Props) {
  const [error, setError] = useState<ApiError>()

  useEffect(() => {
    apiClient.interceptors.response.use(
      (res) => res,
      (error) => {
        const { status } = error.response
        if (status === 401) {
          setError(ApiError.unauthorized)
        }
        return Promise.reject(error)
      }
    )
  }, [])

  return error === ApiError.unauthorized ? (
    <UnauthorizedError />
  ) : (
    props.children
  )
}

export function ApiErrorCatcher(
  func: (error: AxiosError) => void
): (error: AxiosError) => void {
  return (error) => {
    if (error.response?.status === 401) {
      // This occurs when unauthorized happens, we don't want to do anything as the browser
      // will redirect for authorization.
      return
    }
    return func(error)
  }
}
