import React, { useState } from 'react'
import { Container, Typography, Tabs, Tab } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  AdCardDetails,
  AdCardKey,
  AdTextType
} from '@hypotenuse/common/src/interfaces/AdvertisingText'
import { useHistory } from 'react-router-dom'
import { MarketingCardList } from '../components/MarketingCardList'
import facebook_logo from '../assets/marketing/facebook.svg'
import linkedin_logo from '../assets/marketing/linkedin.svg'
import google_ads_logo from '../assets/marketing/google.svg'
import instagram_logo from '../assets/marketing/instagram.svg'
import headlines_logo from '../assets/marketing/headlines.svg'
import suggestion_logo from '../assets/marketing/question.svg'
import rewriter_logo from '../assets/marketing/rewriter.svg'
import category_page_logo from '../assets/marketing/category_page.svg'
import landing_page_logo from '../assets/marketing/landing_page.svg'
import eli5_logo from '../assets/marketing/eli5.svg'
import email_logo from '../assets/marketing/email.svg'
import video_intro_logo from '../assets/marketing/video_script.svg'
import meta_desc_logo from '../assets/marketing/meta_desc.svg'
import marketing_angles_logo from '../assets/marketing/marketing_angles.svg'
import press_release_logo from '../assets/marketing/press_release.svg'
import summarise_logo from '../assets/marketing/summarise.svg'
import youtube_title_logo from '../assets/marketing/youtube.svg'
import bio_writer_logo from '../assets/marketing/bio_writer.svg'
import { SHOW_MARKETING_TABS } from '../utils/Constants'
import {
  adHeaders,
  adSubHeaders
} from '../components/advertising/form/AdTextFormHeader'
import {
  MobileWarningBanner,
  useMobileWarningBanner
} from '../components/mobileWarningBanner'

interface MarketingDashboardProps {
  banners?: React.ReactNode
  onOpenChat: () => void
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      textAlign: 'left'
    },
    header: {
      margin: 0
    }
  })
)

export const marketingRoutes = {
  [AdTextType.facebook]: '/advertising/facebook',
  [AdTextType.google]: '/advertising/google',
  [AdTextType.instagram]: '/advertising/instagram',
  [AdTextType.headline]: '/advertising/headline',
  [AdTextType.linkedin]: '/advertising/linkedin',
  [AdTextType.rewriter]: '/advertising/rewriter',
  [AdTextType.category_page]: '/advertising/category_page',
  [AdTextType.landing_page]: '/advertising/landing_page',
  [AdTextType.eli5]: '/advertising/eli5',
  [AdTextType.email]: '/advertising/email_copy',
  [AdTextType.video_intro]: '/advertising/video_intro',
  [AdTextType.meta_desc]: '/advertising/meta_desc',
  [AdTextType.marketing_angles]: '/advertising/marketing_angles',
  [AdTextType.press_release]: '/advertising/press_release',
  [AdTextType.summarise]: '/advertising/summarise_copy',
  [AdTextType.youtube_title]: '/advertising/youtube_title',
  [AdTextType.bio_writer]: '/advertising/bio_writer'
}

const marketingDashboardLogos = {
  [AdTextType.facebook]: facebook_logo,
  [AdTextType.linkedin]: linkedin_logo,
  [AdTextType.google]: google_ads_logo,
  [AdTextType.instagram]: instagram_logo,
  [AdTextType.headline]: headlines_logo,
  [AdTextType.rewriter]: rewriter_logo,
  [AdTextType.category_page]: category_page_logo,
  [AdTextType.landing_page]: landing_page_logo,
  [AdTextType.eli5]: eli5_logo,
  [AdTextType.email]: email_logo,
  [AdTextType.video_intro]: video_intro_logo,
  [AdTextType.meta_desc]: meta_desc_logo,
  [AdTextType.marketing_angles]: marketing_angles_logo,
  [AdTextType.press_release]: press_release_logo,
  [AdTextType.summarise]: summarise_logo,
  [AdTextType.youtube_title]: youtube_title_logo,
  [AdTextType.bio_writer]: bio_writer_logo
}

type MarketingTab = {
  title: string
  filter: (val: AdCardKey) => boolean
}

const tabs: MarketingTab[] = [
  {
    title: 'All',
    filter: (_copy: AdCardKey) => true
  }
]

const MarketingDashboard = (props: MarketingDashboardProps) => {
  const { banners, onOpenChat } = props
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const mobileWarningBannerProps = useMobileWarningBanner()
  const COPY_TYPES: AdCardDetails[] = Object.keys(AdTextType)
    .map((ad) => {
      const adType = ad as AdTextType
      return {
        key: adType,
        title: adHeaders[adType],
        subtitle: adSubHeaders[adType],
        onClick: () => history.push(marketingRoutes[adType]),
        logoSrc: marketingDashboardLogos[adType]
      }
    })
  // Add in suggestion card
  COPY_TYPES.push({
    key: 'suggestion',
    title: "Can't find a content type?",
    subtitle:
      "Suggest what we should add next. We'll send our AI back to school to learn it!",
    onClick: onOpenChat,
    logoSrc: suggestion_logo
  })
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0) // Default is 0 cause it's the first one
  const currentTab = tabs[currentTabIndex]

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTabIndex(newValue)
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.root}>
        <Stack>
          <h2 className={classes.header}>Marketing Copy</h2>
          <Typography
            variant="body1"
            style={{ color: theme.palette.grey[500] }}
          >
            Write winning copy to drive engagement and conversions.
          </Typography>
        </Stack>
        <MobileWarningBanner {...mobileWarningBannerProps} />
        {banners}
        {
          //TODO: Make tabs highlighted and show divider
          SHOW_MARKETING_TABS && (
            <Tabs
              value={currentTab.title}
              onChange={handleTabChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((marketingTab) => (
                <Tab label={marketingTab.title} key={marketingTab.title} />
              ))}
            </Tabs>
          )
        }
        <MarketingCardList
          copyTypes={COPY_TYPES.filter((copy) => currentTab.filter(copy.key))}
        />
      </Container>
    </>
  )
}

export default MarketingDashboard
