import React from 'react'
import { AdCardDetails } from '../interfaces/AdvertisingText'
import { MarketingCopyCard } from './MarketingCopyCard'
import { Grid } from '@material-ui/core'

interface MarketingCardListProps {
  copyTypes: AdCardDetails[]
}

export const MarketingCardList = (props: MarketingCardListProps) => {
  const { copyTypes } = props
  return (
    <Grid container spacing={3}>
      {copyTypes.map((adCardDetails) => (
        <Grid item key={adCardDetails.key} xs={12} sm={6} md={4}>
          <MarketingCopyCard {...adCardDetails} />
        </Grid>
      ))}
    </Grid>
  )
}
