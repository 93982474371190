import React from 'react'
import { Popup } from 'semantic-ui-react'
import { SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS } from '@hypotenuse/common/src/utils/Constants'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import SyncIcon from '@material-ui/icons/Sync'
import { ProductSyncStatus } from '../utils/Interfaces'
import { useConfirmationDialog } from '@hypotenuse/common/src/hooks/useConfirmationDialog'
import EnhancedButton from '../../../common/src/components/EnhancedButton'

export interface Props {
  productSyncStatus: ProductSyncStatus
  onImportProducts: () => void
}

export const ImportProductsButton: React.FC<Props> = (props) => {
  const { productSyncStatus, onImportProducts } = props
  const {
    AlertDialog,
    alertDialogProps,
    getUserConfirmation: getImportConfirmation
  } = useConfirmationDialog({
    title: 'Import Products',
    confirmText: 'Continue',
    cancelText: 'Back',
    content: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p style={{ textAlign: 'left' }}>
          You're about to re-import all products from your store.
          <br />
          <br />
          This can take some time if you have a lot of products.
        </p>
      </div>
    ),
    disableDontShowAgain: true
  })

  const handleImportProducts = async () => {
    const { isConfirmed } = await getImportConfirmation()
    if (isConfirmed) {
      await onImportProducts()
    }
  }

  return (
    <>
      <AlertDialog {...alertDialogProps} />
      <Popup
        popperModifiers={SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS}
        trigger={
          <EnhancedButton
            variant="outlined"
            color={productSyncStatus.status === 'failed' ? 'error' : 'default'}
            size="small"
            disabled={
              productSyncStatus.status === 'loading' ||
              productSyncStatus.status === 'not-loaded'
            }
            loading={productSyncStatus.status === 'loading'}
            endIcon={
              productSyncStatus.status === 'failed' ? (
                <ErrorOutline />
              ) : (
                <SyncIcon />
              )
            }
            onClick={handleImportProducts}
          >
            {productSyncStatus.status === 'loading'
              ? 'Loading Products'
              : 'Import Products'}
          </EnhancedButton>
        }
        position="bottom center"
      >
        {productSyncStatus.status === 'failed' ? (
          <span>
            Something went wrong while importing your products. Click here to
            try again.
          </span>
        ) : (
          <span>
            Pull the latest product changes from your Shopify store to Tako.
          </span>
        )}
      </Popup>
    </>
  )
}
