import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import React from 'react'
import BeaconArticleLink from '../../../integrations/HelpScout/components/BeaconArticleLink'
import {
  AdTextType,
  BeaconArticleIdSlots
} from '../../../interfaces/AdvertisingText'

interface AdTextFormHeaderProps {
  adTextType: AdTextType
  beaconArticleIds: Record<BeaconArticleIdSlots, string | undefined>
}

export const adHeaders = {
  [AdTextType.google]: 'Google ads',
  [AdTextType.instagram]: 'Instagram captions',
  [AdTextType.facebook]: 'Facebook ads',
  [AdTextType.headline]: 'Website headlines and slogans',
  [AdTextType.linkedin]: 'Linkedin posts',
  [AdTextType.rewriter]: 'Rewrite content',
  [AdTextType.category_page]: 'Category page descriptions',
  [AdTextType.landing_page]: 'Landing page content',
  [AdTextType.eli5]: 'Simplify language',
  [AdTextType.email]: 'Email copy',
  [AdTextType.video_intro]: 'Video hook and introduction',
  [AdTextType.meta_desc]: 'Meta titles and descriptions',
  [AdTextType.marketing_angles]: 'Marketing angles',
  [AdTextType.press_release]: 'Press releases',
  [AdTextType.summarise]: 'Summarize content', // Note the change in spelling (UK to US)
  [AdTextType.youtube_title]: 'Youtube titles and descriptions',
  [AdTextType.bio_writer]: 'Bio writer'
}

export const adSubHeaders = {
  [AdTextType.google]:
    'Create effective and persuasive ads that generate leads and sales.',
  [AdTextType.instagram]:
    'Write scroll-stopping captions that encourages people to stop, look, and like.',
  [AdTextType.facebook]:
    'Craft powerful and compelling ads that speak to your target market.',
  [AdTextType.headline]:
    'Develop headlines and taglines that pack a punch and convert visitors into customers.',
  [AdTextType.linkedin]:
    'Engage your target audience with thought-provoking and persuasive content.',
  [AdTextType.rewriter]:
    'Refresh and repurpose content while making it more engaging and effective.',
  [AdTextType.category_page]:
    'Optimize your category pages with descriptions that increase organic traffic.',
  [AdTextType.landing_page]:
    'Create benefit-focused landing pages that entice visitors to take the next step.',
  [AdTextType.eli5]:
    'Make complex concepts easy to understand and jargon-free.',
  [AdTextType.email]:
    'Write copy to get your message across, no matter how crowded the inbox.',
  [AdTextType.video_intro]:
    'Write a catchy and intriguing hook to reel viewers in and keep them watching.',
  [AdTextType.meta_desc]:
    'Write meta titles and descriptions that are optimized for search engines.',
  [AdTextType.marketing_angles]:
    'Generate marketing angles that are persuasive and compelling.',
  [AdTextType.press_release]:
    'Write press releases that are newsworthy and engaging.',
  [AdTextType.summarise]:
    'Summarize content to make it more concise and say more with less.', // Note the change in spelling (UK to US)
  [AdTextType.youtube_title]:
    'Optimize your videos for greater visibility and higher engagement.',
  [AdTextType.bio_writer]:
    'Elevate any page with a well-written bio that will make people want to know more',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spanContent: {
      color: theme.palette.grey[500]
    },
    noBottomMargin: {
      marginBottom: '0px !important'
    }
  })
)

export const AdTextFormHeader = (props: AdTextFormHeaderProps) => {
  const { adTextType, beaconArticleIds } = props
  const classes = useStyles()
  const headerContent = adHeaders[adTextType]
  const spanContent = adSubHeaders[adTextType]

  const adsGenerationBeaconLink = beaconArticleIds.adsGeneration && (
    <BeaconArticleLink articleId={beaconArticleIds.adsGeneration}>
      <p>Need Help?</p>
    </BeaconArticleLink>
  )

  return (
    <div>
      <Typography variant="h5" className={classes.noBottomMargin}>
        {headerContent}
      </Typography>
      <span className={classes.spanContent}>{spanContent}</span>{' '}
      {adsGenerationBeaconLink}
    </div>
  )
}
