import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { capitalize } from 'lodash-es'

import MuiButton, {
  ButtonProps as MuiButtonProps
} from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

// Import this if you need coloured outlined or contained that is
// not within MUI's default primary, secondary or default color.
// To customize the respective colors, declare them in your theme.
// Adapted off: https://stackoverflow.com/a/64560059.
export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'success'
  | 'warning'
  | 'default'
  | 'inherit'
  | 'info'

export type ButtonVariant = 'contained' | 'outlined'

interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color: ButtonColor
  loading?: boolean
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    outlinedSuccess: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main
    },
    outlinedError: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main
    },
    outlinedWarning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main
    },
    outlinedInfo: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark
      }
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.info.dark
      }
    }
  })
)

const Button: React.FC<ButtonProps> = (props) => {
  const { children, color, endIcon, loading, ...restProps } = props
  const classes = useStyles()
  const className = classes[`${restProps.variant}${capitalize(color)}`]
  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color) > -1
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : undefined
  return (
    <MuiButton
      {...restProps}
      color={colorProp}
      className={className}
      endIcon={
        (loading && <CircularProgress color="inherit" size={14} />) || endIcon
      }
    >
      {children}
    </MuiButton>
  )
}

Button.displayName = 'Button'

export default Button
