// More configs: https://material-ui.com/customization/palette/
import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'hsl(220, 91%, 54%)'
    }
  },
  shape: {
    borderRadius: 16
  },
  overrides: {
    // MUI's buttons look really bad, esp since they are uppercase
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: 'grey'
        }
      }
    }
  }
})

theme.shadows[1] =
  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 20%) 0px 1px 3px -1px'
theme.shadows[2] =
  '0px 2px 1px -1px rgb(0 0 0 / 12%), 0px 1px 1px 2px rgb(0 0 0 / 6%)'
theme.shadows[3] = 'box-shadow 0.2s ease-in-out, border 0.2s ease-in-out'
theme.shadows[4] = '0px 4px 8px rgba(38, 38, 38, 0.2)'

export { theme }
