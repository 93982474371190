import { SHOPIFY_HELPHERO_TUTORIAL_TOUR } from './Constants'
import { User } from './Interfaces'

export const titleCase = (text: string) => {
  let splitStr = text.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const replacePeriodWithUnderscore = (text: string) => {
  return text.replace(/\./g, '_')
}

export const getDateFromIsoString = (date: string) => {
  return date.split('T')[0]
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  document.body.removeChild(link)
}

export function paginate<T>(
  list: T[],
  currentPage: number,
  pageSize: number
): T[] {
  return list.slice((currentPage - 1) * pageSize, currentPage * pageSize)
}

export const isValidDate = (date: any) => {
  return date instanceof Date && !isNaN(date.getTime())
}

export const getIsTutorialComplete = (user: User): boolean => {
  const completedToursIds = user.completedToursIds
  return (
    completedToursIds === undefined ||
    completedToursIds.includes(SHOPIFY_HELPHERO_TUTORIAL_TOUR.id)
  )
}

export const getNameFromShopifyUserName = (username: string): string => {
  const SHOPIFY_SUBDOMAIN = '.myshopify.com'
  const shopifySubdomainIndex = username.search(SHOPIFY_SUBDOMAIN)
  return shopifySubdomainIndex === -1
    ? username
    : username.slice(0, shopifySubdomainIndex)
}
