import React from 'react'
import { Grid } from '@material-ui/core'
import { Header, Modal, ModalProps } from 'semantic-ui-react'
import EnhancedButton, { ButtonColor, ButtonVariant } from './EnhancedButton'

interface Props extends ModalProps {
  isOpen: boolean
  header: React.ReactNode
  headerStyle?: React.CSSProperties
  body: React.ReactNode
  trigger?: React.ReactNode
  secondaryVariant?: ButtonVariant
  secondaryColor?: ButtonColor
  secondaryText?: React.ReactNode
  secondaryIcon?: React.ReactNode
  secondaryFunc: Function
  secondaryDisabled?: boolean
  primaryVariant?: ButtonVariant
  primaryColor?: ButtonColor
  primaryText?: React.ReactNode
  primaryIcon?: React.ReactNode
  primaryDisabled?: boolean
  primaryFunc: Function
  disabled?: boolean
  noBodyPadding?: boolean
}

/**
 * Generic modal component with primary and secondary actions.
 *
 * @deprecated This component uses the Modal from semantic-ui, which we're moving away from
 */
export default function GeneralModal(props: Props) {
  const {
    primaryColor = 'primary',
    primaryVariant = 'contained',
    secondaryColor = 'primary',
    secondaryVariant = 'outlined',
    header,
    ...modalProps
  } = props
  return (
    <Modal
      closeIcon
      // TODO: Rename `isOpen` to `open` to match Semantic UI's ModalProps
      open={props.isOpen}
      onClose={props.disabled ? undefined : props.onClose}
      {...modalProps}
    >
      <Header style={props?.headerStyle} content={header} />
      {props.noBodyPadding === true ? (
        props.body
      ) : (
        <Modal.Content>{props.body}</Modal.Content>
      )}
      {(props.primaryText || props.secondaryText) && (
        <Modal.Actions>
          <Grid container alignItems="center" justify="flex-end" spacing={1}>
            <Grid item>
              {props.secondaryText && (
                <EnhancedButton
                  variant={secondaryVariant}
                  color={secondaryColor}
                  disabled={props.disabled || props.secondaryDisabled}
                  startIcon={props.secondaryIcon}
                  onClick={() => props.secondaryFunc()}
                  data-testid={`${props.secondaryText}__secondaryBtn`}
                >
                  {props.secondaryText}
                </EnhancedButton>
              )}
            </Grid>
            <Grid item>
              {props.primaryText && (
                <EnhancedButton
                  variant={primaryVariant}
                  color={primaryColor}
                  disabled={props.disabled || props.primaryDisabled}
                  startIcon={props.primaryIcon}
                  onClick={() => props.primaryFunc()}
                  data-testid={`${props.primaryText}__primaryBtn`}
                >
                  {props.primaryText}
                </EnhancedButton>
              )}
            </Grid>
          </Grid>
        </Modal.Actions>
      )}
    </Modal>
  )
}

GeneralModal.defaultProps = {
  dimmer: 'inverted',
  closeIcon: true
}
