/**
 * Low-level interface to the content generation API.
 * Avoid using these functions directly. Instead, prefer the higher
 * level generation API hook or HOC.
 */
import { apiClient } from '../utils/ApiClient'
import { AxiosRequestConfig } from 'axios'
import {
  AdTextFolderSearchQuery,
  AdTextType
} from '../interfaces/AdvertisingText'
import { ContentGenerationType } from '../interfaces/ContentGeneration'
import { Description } from '../interfaces/Products'

/**
 * Wrapper around AxiosRequestConfig to add typing for the `params` property
 */
export interface IGenerationAPIRequestConfig<Payload = void, Params = void>
  extends Omit<AxiosRequestConfig<Payload>, 'params'> {
  params?: Params
}

// ===== Generation API =====
/**
 * URL Query params for the content generation API endpoint
 */
export interface IGenerationAPIParams {
  // TODO: Specifying both generation_type and copy_type is redundant - unify.
  /**
   * The type of content to generate
   */
  generation_type?: ContentGenerationType
  /**
   * Ad text copy type (to be deprecated)
   * @deprecated
   */
  copy_type?: AdTextType
  /**
   * Product ID to be sent as query parameter to escape (starlette's) FastAPI's issue with escape sequencing
   * See: https://github.com/encode/starlette/issues/826
   */
  productId: string
}

/**
 * Request body for the content generation API endpoint
 */
export type IGenerationAPIPayload = [number, number][]

/**
 * Call the content generation API.
 * Take note that this function doesn't handle any errors that the endpoint may return.
 * Errors are currently handled in the GenerationAPIProvider hook where this fn is used. If you're
 *  calling this function directly, make sure you handle errors such as rate-limiting, credit exhaustion, etc.
 */
export const apiGenerateContent = async (
  requestConfig: IGenerationAPIRequestConfig<
    IGenerationAPIPayload,
    IGenerationAPIParams
  >
) => {
  const { data, ...config } = requestConfig
  return await apiClient.post<void>(
    '/describe/product/', // productId as a query parameter in finalConfig.params
    data,
    config
  )
}

// ===== Rewrite API =====
/**
 * Common options for all generation API handlers
 * URL Query params for the rewrite API endpoint
 */
export interface IRewriteAPIParams {
  // Nothing here for now
}

/**
 * Request body for the rewrite API endpoint
 */
export interface IRewriteAPIPayload {
  /**
   * Original content to be rewritten
   */
  original_content: string
}

/**
 * Call the rewrite API.
 * Take note that this function doesn't handle any errors that the endpoint may return.
 * Errors are currently handled in the GenerationAPIProvider hook where this fn is used. If you're
 *  calling this function directly, make sure you handle errors such as rate-limiting, credit exhaustion, etc.
 */
export const apiRewriteText = async (
  requestConfig: IGenerationAPIRequestConfig<
    IRewriteAPIParams,
    IRewriteAPIPayload
  >
) => {
  const { data, ...config } = requestConfig
  return await apiClient.post<string>(
    '/rewrite', // productId as a query parameter in finalConfig.params
    data,
    config
  )
}

// ==== Write more API =====
/**
 * Context for content generation endpoint
 */
export interface WriteMoreGenerationContext {
  prefix: string
  suffix?: string
  should_continue_line: boolean
}

/**
 * Body for the write more API endpoint
 */
export type IWriteMoreAPIPayload = WriteMoreGenerationContext

/**
 * Axios request config for the write more API endpoint. Has a different payload
 * from the normal request config.
 */
export interface IWriteMoreAPIRequestConfig
  extends AxiosRequestConfig<IWriteMoreAPIPayload> {
  params: IGenerationAPIParams
}

// /**
//  * Options specific to the immediate root generation API caller function
//  */
// export interface GenerateImmediateContentRootOptions
//   extends GenerateContentHandlerOptions {
//   /**
//    * Config passed to the underlying axios request function
//    */
//   requestConfig: IImmediateGenerationAPIRequestConfig
// }

/**
 * Call the immediate generation API. We keep it distinct from the
 * normal generation API due to different return types.
 */
export const apiWriteMore = async (
  requestConfig: IWriteMoreAPIRequestConfig
) => {
  const { data, ...config } = requestConfig
  return await apiClient.post<Description[]>(
    '/write_more/product', // productId as a query parameter in finalConfig.params
    data,
    config
  )
}

// TODO: Standardize batch generation across Phoenix & Tako.

export interface GenerateBatchInRequest {
  productIds: string[]
  // TODO: add in prodDescQuery here. need to move ProductSearchQuery to common
  // prodDescQuery: ProductSearchQuery
  adTextQuery?: AdTextFolderSearchQuery
  jobName: string | undefined
  generationType: ContentGenerationType
}

//  Currently blocked by differing interfaces/flows.
export const apiGenerateBatch = async (
  requestConfig: GenerateBatchInRequest
) => {
  let body = {
    product_ids: requestConfig.productIds,
    ad_text_query: requestConfig.adTextQuery,
    job_name: requestConfig.jobName,
    generation_type: requestConfig.generationType
  }
  return apiClient.post('/describe/products', body)
}
