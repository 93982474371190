import { useMemo } from 'react'
// The BroadcastChannel API is not supported in all environments and browsers. This package acts as a polyfill.
import { BroadcastChannel } from 'broadcast-channel'

/**
 * Hook that uses the Broadcast API to send messages between windows/tabs of the same application.
 * Specify the callback function `onMessage` to run when a message is received from another window.
 * Use the `postMessage` function to push messages to other tabs.
 *
 * Broadcast API Reference: {@link https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API}
 */
const useBroadcast = <T,>(
  channelName: string,
  onMessage: (message: T) => void
): { postMessage: (message: T) => void } => {
  const channel = useMemo(() => new BroadcastChannel(channelName), [
    channelName
  ])

  channel.onmessage = function (event) {
    onMessage(event)
  }

  return { postMessage: (message) => channel.postMessage(message) }
}

export default useBroadcast
