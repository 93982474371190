import React from 'react'
import { Image } from 'semantic-ui-react'
import { Box, Typography, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { AdCardDetails } from '../interfaces/AdvertisingText'
import Stack from './atoms/Stack'

const useStyles = makeStyles((theme) =>
  createStyles({
    mainCard: {
      transition: 'all 0.2s ease-out',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(145 158 171 / 16%)'
      },
      boxShadow: theme.shadows[1],
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      width: '100%',
      height: '120px',
      cursor: 'pointer',
      marginTop: theme.spacing(4)
    }
  })
)

export const MarketingCopyCard = (props: AdCardDetails) => {
  const { title, subtitle, onClick, logoSrc } = props
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Stack className={classes.mainCard} onClick={onClick}>
      <Box position={'absolute'} top={-20} left={20}>
        {/*TODO: Make this an <img> tag */}
        {logoSrc && <Image src={logoSrc} size={'mini'} />}
      </Box>
      <Stack textAlign="left" spacing={0.5} mt={2}>
        <Typography variant="h6">{title}</Typography>
        <Typography
          variant="body2"
          style={{ color: theme.palette.grey['500'] }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  )
}
