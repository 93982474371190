import { Article } from './types'

export enum ArticleName {
  keywords = 'keywords',
  productImage = 'productImage',
  adTextTargetAudience = 'adTextTargetAudience',
  welcome = 'welcome',
  import = 'import',
  catalogs = 'catalogs',
  products = 'products',
  blog = 'blog',
  ads = 'ads',
  plagiarismChecker = 'plagiarismChecker',
  imageGen = 'imageGen',
}

export const beaconArticles: { [key in ArticleName]: Article } = {
  [ArticleName.keywords]: { id: '612881f4f886c9486f8dca92' },
  [ArticleName.productImage]: { id: '612ded5200c03d6720752823' },
  [ArticleName.welcome]: { id: '611e6460b37d837a3d0e5500' },
  [ArticleName.adTextTargetAudience]: { id: '612dee0e2b380503dfdec9c5' },
  [ArticleName.import]: { id: '613058e700c03d6720753377' },
  [ArticleName.catalogs]: { id: '61308f3a2b380503dfded655' },
  [ArticleName.products]: { id: '613082d900c03d6720753402' },
  [ArticleName.blog]: { id: '615fae6812c07c18afddb7fd' },
  [ArticleName.ads]: { id: '627dd5f68c9b585083489524' },
  [ArticleName.plagiarismChecker]: { id: '62dfa3c4b828a850e9e61301' },
  [ArticleName.imageGen]: {id: '6302f04086337c0b7a87b321'}
}

export enum TakoBeaconArticleName {
  productFeatures = 'productFeatures',
  googleAdsTips = 'googleAdsTips',
  facebookAdsTips = 'facebookAdsTips',
  generatingDescriptions = 'generatingDescriptions',
  generatingAds = 'generatingAds'
}

export const takoBeaconArticles: { [key in TakoBeaconArticleName]: Article } = {
  [TakoBeaconArticleName.productFeatures]: { id: '615bf92f2b380503dfdf76c0' },
  [TakoBeaconArticleName.googleAdsTips]: { id: '615bf92f2b380503dfdf76c0' },
  [TakoBeaconArticleName.facebookAdsTips]: { id: '615c1212e5648623c88e1800' },
  [TakoBeaconArticleName.generatingDescriptions]: {
    id: '61775be412c07c18afde0eaa'
  },
  [TakoBeaconArticleName.generatingAds]: {
    id: '617760430332cb5b9e9b75af'
  }
}
